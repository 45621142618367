import { formatISO } from 'date-fns'
import { db, Plb } from '../store/db'
import {
  syncWithRemote,
  updateSingleSyncProperty,
} from '../common/syncWithRemote'
import { log } from '../common/utils'

export const deletePlb = async (id?: string): Promise<void> => {
  if (!id) return
  await db.plbs.update(id, {
    status: 'deleted',
    updatedAt: formatISO(new Date()),
  })
  await updateSingleSyncProperty([{ key: 'plbsLocalUpdates', value: true }])
}

export const addPlb = async (plb: Plb): Promise<void> => {
  db.plbs.add(plb)
  await updateSingleSyncProperty([{ key: 'plbsLocalUpdates', value: true }])
}

export const getPlb = async (plbId?: string): Promise<Plb | undefined> => {
  return plbId ? await db.plbs.get(plbId) : undefined
}

export const updatePlb = async (plb: Plb): Promise<void> => {
  await db.plbs.update(plb.plbId, {
    ...plb,
    updatedAt: formatISO(new Date()),
  })
  await updateSingleSyncProperty([{ key: 'plbsLocalUpdates', value: true }])
}

export const replacePlbsTable = async (newPlbs: Plb[]): Promise<void> => {
  try {
    const allPlbs = await db.plbs.toArray()
    const allPlbIds = allPlbs.map((u) => u.plbId)
    await db.plbs.bulkDelete(allPlbIds)
    await db.plbs.bulkPut(newPlbs)
  } catch (e: any) {
    log(`Error while replacing plbs table: ${e.message}`)
  }
}
