import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { format, toDate } from 'date-fns'
import { db, User, Walk } from '../store/db'
import { LogItem } from '../store/store'
import { constants } from '../config'
import { getUser } from '../User/user.model'
import { getWalk } from '../Walks/walks.model'
import { debounce } from 'lodash'

export const makeNewLogItem = (
  logMessage: string,
  logs: LogItem[]
): LogItem[] => {
  const logId = logMessage.slice(0, 100).replaceAll(' ', '')
  const lastLog = logs[0]
  let oldLogs = logs.slice(0, 50) // Max 50 items
  let repeats = 1

  if (lastLog && lastLog.id === logId) {
    oldLogs.shift()
    repeats = lastLog.repeat + 1
  }

  const time = new Date().toTimeString().slice(0, 8)
  const newLogs = [
    {
      id: logId,
      time: time,
      data: logMessage,
      repeat: repeats,
    },
    ...oldLogs,
  ]
  return newLogs
}

export const makeNewSingleLogItem = (logMessage: string) => {
  const time = new Date().toTimeString().slice(0, 8)
  return {
    time: time,
    data: logMessage,
    repeat: 1,
  }
}

let lastMessage = ''
export const log = async (
  message: string,
  toAnalytics = false,
  extraData: { userId?: string; walkId?: string } = {}
): Promise<void> => {
  console.log('log:', message, toAnalytics ? extraData : '')

  // Translate userId or walkIk to a title, add to the message
  const user = await getUser(extraData.userId)
  const userName = user ? ` ${user.fullName}` : ` ${extraData.userId || ''}`
  const walk = await getWalk(extraData.walkId)
  const walkTitle = walk ? ` ${walk.title}` : ` ${extraData.walkId || ''}`
  const expandedMessage = `${message}${userName}${walkTitle}`

  const messageObject = makeNewSingleLogItem(expandedMessage)

  if (expandedMessage === lastMessage) {
    // Update the repeat count and time
    const lastLog = await db.logItems.toCollection().last()
    if (lastLog)
      db.logItems.update(lastLog, {
        repeat: lastLog.repeat + 1,
        time: messageObject.time,
      })
    return
  }

  // Keep the last message
  lastMessage = expandedMessage

  // Add to the db
  try {
    const logId = await db.logItems.add(messageObject)
  } catch (error) {
    console.log('dexxie error', error)
  }

  // Firebase analytics
  if (toAnalytics) {
    const firebaseConfig = {
      apiKey: 'AIzaSyBuwabxEyJpeDI2aJn4OkRymSpXRh9KaxQ',
      authDomain: 'tripsheets-bd1e4.firebaseapp.com',
      projectId: 'tripsheets-bd1e4',
      storageBucket: 'tripsheets-bd1e4.appspot.com',
      messagingSenderId: '379383222116',
      appId: '1:379383222116:web:da64dcfe018ab6d62cb471',
      measurementId: 'G-32H321CLLG',
    }
    const app = initializeApp(firebaseConfig)
    const analytics = getAnalytics(app)

    const env = constants.ENVIRONMENT.slice(0, 2).toUpperCase() + ':'
    logEvent(analytics, env + expandedMessage, extraData)

    // Call my callable server function
    // const functions = getFunctions(app)
    // const addMessage = httpsCallable(functions, 'addMessage')
    // addMessage({ text: 'text from tripsheets logger' })
    //   .then((result) => {
    //     /** @type {any} */
    //     console.log('result from callable', result)
    //   })
    //   .catch((error) => {
    //     console.log('error from callable', error)
    //   })
  }
}

export const getLocation = async (): Promise<string> => {
  return new Promise((resolve) => {
    // Need to test these separately 'cos the minified version is buggy
    if (!navigator) {
      resolve('')
    } else if (!navigator.geolocation) {
      resolve('')
    } else if (!navigator.geolocation.getCurrentPosition) {
      resolve('')
    } else {
      navigator.geolocation.getCurrentPosition(
        (d) => {
          const lon = d.coords.longitude.toFixed(5)
          const lat = d.coords.latitude.toFixed(5)
          const pos = `LON:${lon},LAT:${lat}`
          resolve(pos)
        },
        (e) => {
          console.error(`Error while getting gps location:`, e)
          resolve(`Error while getting gps location: ${e}`)
        }
      )
    }
  })
}

export const isOnline = (): boolean => {
  // Get online status
  const onlineStatusNav = navigator.onLine
  db.appStatus.update('Tripsheets', { haveInternet: onlineStatusNav })
  return Boolean(onlineStatusNav)
}

export const isLeader = (
  user?: User | string,
  walk?: Walk,
  _falseIfMultiple = false
): boolean => {
  const theUserId = typeof user === 'string' ? user : user?.userId
  const leaderIds = walk?.leaderId
  if (!theUserId || !leaderIds) return false
  if (typeof leaderIds === 'string') return leaderIds === theUserId
  return leaderIds.includes(theUserId)

  /* Not using _falseIfMultiple for now. The idea is: if there is >1 leader,
     then return false even if the user is a leader. This allows a leader to be
     removed from the walkers list.
     But: then that person must also be removed from the leaders list, otherwise the
     badges and other things go wrong ('cos there is a non-existant leader)
  if (!leaderIds.includes(theUserId)) return false
  return leaderIds.length < 2
   */
}

export const getWalkLeaderNames = (walk?: Walk, users?: User[]): string => {
  if (!walk || !users) return ''
  return typeof walk.leaderId === 'string'
    ? users.find((u) => u.userId === walk.leaderId)?.fullName || ''
    : users
        .filter((u) => walk.leaderId.includes(u.userId))
        .map((u) => u.fullName)
        .join(', ')
}

export const formatWalkTitle = (title?: string, abbreviate = false) => {
  if (!title) return ''
  if (!abbreviate) return title
  return title.length > 20 ? `${title.slice(0, 17)}...` : title
}

export const formatDateTime = (
  dateTime?: string,
  expectedFormat = 'dd-MM-yyyy h:mmaaa'
): string => {
  if (!dateTime) return ''
  if (!isValidDate(dateTime)) return dateTime
  try {
    return format(new Date(dateTime), expectedFormat)
  } catch (error: any) {
    if (!error?.message.includes('Invalid time value'))
      log(`Error formatting dateTime: ${error}`)
    return dateTime
  }
}

export const formatMultiDateTime = (
  dateTime?: string,
  dateTimeTo?: string
): string => {
  if (!dateTime || !dateTimeTo) return ''
  if (!isValidDate(dateTime)) return `${dateTime} - ${dateTimeTo}`
  if (!isValidDate(dateTimeTo)) return `${dateTime} - ${dateTimeTo}`
  try {
    return `${format(new Date(dateTime), 'EEE d MMM')} - ${format(
      new Date(dateTimeTo),
      'd MMM yyyy'
    )}`
  } catch (error: any) {
    if (!error?.message.includes('Invalid time value'))
      log(`Error formatting dateTime: ${error}`)
    return `${dateTime} - ${dateTimeTo}`
  }
}

// A somewhat complex test if we really have a date....
export const isValidDate = (date?: Date | string) => {
  if (!date) return false
  const dateObject = typeof date === 'string' ? new Date(date) : date
  try {
    return !isNaN(toDate(dateObject).getTime())
  } catch (e) {
    // Ignore
  }
  return false
}

export const collapseText = (text: string | undefined): string =>
  text ? text.toLowerCase().replaceAll(' ', '') : ''

type Rows = Walk[] | User[]
export const handleLongList = (
  allRows: Rows,
  displayedRows: Rows,
  setDisplayedRows: (value: ((prevState: Rows) => Rows) | Rows) => void,
  setHaveMore: (value: ((prevState: number) => number) | number) => void,
  nrToAdd = 3
) => {
  const handleScroll = debounce((event: any) => {
    // On scroll down, add more to the filteredWalks
    const direction =
      event.movementY || // Pointer event
      event.deltaY // Wheel event
    if (direction < 0) return
    const curNr = displayedRows.length
    const addThese = allRows.slice(curNr, curNr + nrToAdd)
    if (!addThese || !addThese.length) return
    if ('walkId' in addThese[0]) {
      // Walks
      const addTheseUnique = (addThese as Walk[]).filter((add) => {
        const haveIndex = (displayedRows as Walk[]).findIndex(
          (fw) => fw.walkId === add.walkId
        )
        return haveIndex === -1
      })
      setHaveMore(allRows.length - displayedRows.length - addTheseUnique.length)
      setDisplayedRows((prev) => (prev as Walk[]).concat(addTheseUnique))
    } else {
      // Users
      const addTheseUnique = (addThese as User[]).filter((add) => {
        const haveIndex = (displayedRows as User[]).findIndex(
          (fw) => fw.userId === add.userId
        )
        return haveIndex === -1
      })
      setHaveMore(allRows.length - displayedRows.length - addTheseUnique.length)
      setDisplayedRows((prev) => (prev as User[]).concat(addTheseUnique))
    }
  }, 10)
  return handleScroll
}

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
