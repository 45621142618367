import { getS3Filenames } from '../common/s3Functions'
import { getValidTips } from './Header.logic'
import React, { Fragment } from 'react'
import { cloneDeep } from 'lodash'
import { formatISO } from 'date-fns'
import { CreateToastFnReturn } from '@chakra-ui/react'
import { AppStatus, db } from '../store/db'
import { tipTextToHtml } from '../common/textUtils'

export const doTips = (
  toast: CreateToastFnReturn,
  appStatusDexie: AppStatus
) => {
  const fn = async () => {
    // const remoteTipIds = await getS3Filenames('tip-file')
    // Check all the tips statusses. Display only the first valid one
    const validTips = await getValidTips()
    if (!validTips.length) return
    const tip = validTips[0]
    const html = tipTextToHtml(tip)

    if (!toast.isActive(tip.tipId)) {
      toast({
        id: tip.tipId,
        title: 'Tip!',
        description: html,
        status: 'info',
        duration: 20_000,
        position: 'top',
        isClosable: true,
        containerStyle: { mt: '148px' },
      })

      // Update the tip admin
      const tipsAdmin = cloneDeep(appStatusDexie.tipsAdmin || {})
      const tipAdmin = tipsAdmin[tip.tipId] || {
        seenCount: 0,
        lastSeenAt: '',
      }
      tipsAdmin[tip.tipId] = {
        seenCount: tipAdmin.seenCount + 1,
        lastSeenAt: formatISO(new Date()),
      }
      await db.appStatus.update('Tripsheets', { tipsAdmin })
    }
  }
  fn()
}
