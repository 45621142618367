import { db, User } from '../store/db'
import { updateSingleSyncProperty } from '../common/syncWithRemote'
import { log } from '../common/utils'
import { formatISO } from 'date-fns'
import { getPlb } from '../Plbs/plbs.model'

export const deleteUser = async (id?: string): Promise<void> => {
  if (!id) return
  await db.users.update(id, {
    status: 'deleted',
    updatedAt: formatISO(new Date()),
  })
  await updateSingleSyncProperty([{ key: 'usersLocalUpdates', value: true }])
}

export const hardDeleteUserFromDb = async (id?: string): Promise<void> => {
  if (!id) return
  await db.users.delete(id)
  await updateSingleSyncProperty([{ key: 'usersLocalUpdates', value: true }])
}

export const undeleteUser = async (id?: string): Promise<void> => {
  if (!id) return
  await db.users.update(id, {
    status: 'active',
    updatedAt: formatISO(new Date()),
  })
  await updateSingleSyncProperty([{ key: 'usersLocalUpdates', value: true }])
}

export const addUser = async (user: User): Promise<void> => {
  await db.users.add(user)
  await updateSingleSyncProperty([{ key: 'usersLocalUpdates', value: true }])
}

export const updateUser = async (user: User): Promise<void> => {
  await db.users.update(user.userId, {
    ...user,
    updatedAt: formatISO(new Date()),
  })
  await updateSingleSyncProperty([{ key: 'usersLocalUpdates', value: true }])
}

export const getUser = async (userId?: string): Promise<User | undefined> => {
  return userId ? db.users.get(userId) : undefined
}

export const givePlbToUser = async (
  plbId?: string,
  newUserId?: string
): Promise<User | undefined> => {
  if (!plbId && !newUserId) return

  let returnUser: User | undefined
  if (!plbId) {
    // Just remove any plb that the user had
    const newUser = await getUser(newUserId)
    const oldPlbId = newUser?.holdsPlbId
    if (newUserId) {
      await db.users.update(newUserId, {
        ...newUser,
        holdsPlbId: '',
        updatedAt: formatISO(new Date()),
      })
    }

    // Check if the old Plb still has the user as holder
    if (oldPlbId) {
      const plb = await getPlb(oldPlbId)
      if (plb?.heldByUserId === newUserId) {
        await db.plbs.update(oldPlbId, {
          ...plb,
          heldByUserId: '',
          updatedAt: formatISO(new Date()),
        })
      }
    }
  } else if (!newUserId) {
    // Just remove the holder from the plb
    const plb = await getPlb(plbId)
    const oldHolderId = plb?.heldByUserId
    await db.plbs.update(plbId, {
      ...plb,
      heldByUserId: '',
      updatedAt: formatISO(new Date()),
    })

    // Check if the old holder still holds the plb
    if (oldHolderId) {
      const oldHolder = await getUser(oldHolderId)
      if (oldHolder?.holdsPlbId === plbId) {
        await db.users.update(oldHolderId, {
          ...oldHolder,
          holdsPlbId: '',
          updatedAt: formatISO(new Date()),
        })
      }
    }
  } else {
    // Give the plb to the user
    const newUser = await getUser(newUserId)
    const oldPlbIdHeldByUser = newUser?.holdsPlbId
    await db.users.update(newUserId, {
      ...newUser,
      holdsPlbId: plbId,
      updatedAt: formatISO(new Date()),
    })

    // Remove the user from their old Plb
    if (oldPlbIdHeldByUser && oldPlbIdHeldByUser !== 'otherPlb') {
      const plb = await getPlb(oldPlbIdHeldByUser)
      if (plb?.heldByUserId === newUserId) {
        await db.plbs.update(oldPlbIdHeldByUser, {
          ...plb,
          heldByUserId: '',
          updatedAt: formatISO(new Date()),
        })
      }
    }

    // For club plbs, add the user to the plb
    if (plbId && plbId !== 'otherPlb') {
      const plb = await getPlb(plbId)
      const oldUserId = plb?.heldByUserId
      await db.plbs.update(plbId, {
        ...plb,
        heldByUserId: newUserId,
        updatedAt: formatISO(new Date()),
      })

      // Find the previous holder and remove them
      if (oldUserId) {
        const oldUser = await getUser(oldUserId)
        await db.users.update(oldUserId, {
          ...oldUser,
          holdsPlbId: '',
          updatedAt: formatISO(new Date()),
        })

        returnUser = oldUser
      }
    }
  }

  await updateSingleSyncProperty([{ key: 'usersLocalUpdates', value: true }])
  return returnUser
}

export const userIsBlocked = async (userId?: string): Promise<boolean> => {
  const user = await getUser(userId)
  return user?.isBlocked || false
}

export const replaceUsersTable = async (newUsers: User[]): Promise<void> => {
  try {
    const allUsers = await db.users.toArray()
    const allUserIds = allUsers.map((u) => u.userId)
    await db.users.bulkDelete(allUserIds)
    await db.users.bulkPut(newUsers)
  } catch (e: any) {
    log(`Error while replacing users table: ${e.message}`)
  }
}
