import React, { Fragment } from 'react'
import { Box, Button, HStack, IconButton } from '@chakra-ui/react'
import { CheckIcon, DeleteIcon, EmailIcon } from '@chakra-ui/icons'
import { Message, MessageStatus } from '../store/db'
import { ButtonConfirm } from '../common/UtilComponents'

import './messages.css'
import { palette } from '../config'

type Props = {
  // editMessageId: string
  draftMessage?: Partial<Message>
  doEditMessage: (status?: MessageStatus) => Promise<void>
}

export const StatusButtons = (props: Props) => {
  // Note: leaving Start and End statusses to the message component
  let newStatusses: (MessageStatus | 'nochange')[] = []

  switch (props?.draftMessage?.status) {
    case undefined:
      newStatusses = ['deleted', 'sent', 'draft']
      break
    case 'draft':
      newStatusses = ['deleted', 'sent', 'nochange']
      break
    case 'sent':
      newStatusses = ['nochange']
      break
  }

  const isValid = () => {
    return (
      props.draftMessage?.to &&
      props.draftMessage?.toId &&
      props.draftMessage?.message
    )
  }

  const messageAbbrev = (message?: string) => {
    if (!message) return ''
    return message.slice(0, 5) + '...'
  }

  return (
    <>
      <HStack mt={4} spacing={4} justify={'end'} w={'100%'}>
        {newStatusses.map((status) => {
          const isCurrent = props?.draftMessage?.status === status
          switch (status) {
            case 'draft':
              return (
                <Box
                  key={`btn-${status}`}
                  className={isCurrent ? 'redBorder' : ''}
                >
                  <ButtonConfirm
                    text={'so only visible for you'}
                    btnText={'Draft'}
                    okAction={() => props.doEditMessage('draft')}
                    isDisabled={!isValid()}
                    icon={<CheckIcon />}
                  />
                </Box>
              )
            case 'deleted':
              return (
                <Box
                  key={`btn-${status}`}
                  className={isCurrent ? 'redBorder' : ''}
                >
                  <ButtonConfirm
                    text={`message ${messageAbbrev(
                      props.draftMessage?.message
                    )}`}
                    btnText={'Delete'}
                    okAction={() => props.doEditMessage('deleted')}
                    icon={<DeleteIcon />}
                  />
                </Box>
              )
            case 'sent':
              return (
                <Box
                  key={`btn-${status}`}
                  className={isCurrent ? 'redBorder' : ''}
                >
                  <ButtonConfirm
                    text={`message ${
                      props.draftMessage?.message?.slice(0, 5) + '...' || ''
                    }`}
                    btnText={'Send'}
                    okAction={() => props.doEditMessage('sent')}
                    isDisabled={!isValid()}
                    icon={<EmailIcon />}
                  />
                </Box>
              )
            case 'nochange':
              return (
                <Fragment key={`btn-${status}`}>
                  <ButtonConfirm
                    text={`Message stays ${props.draftMessage?.status || ''}`}
                    btnText={''}
                    color={palette.action}
                    okAction={() => props.doEditMessage()}
                    icon={<CheckIcon />}
                  />
                </Fragment>
              )
          }
        })}
      </HStack>
    </>
  )
}
