import Dexie, { Table } from 'dexie'
import { Message, Plb, User, Walk } from './db'

// Increment this on db structure changes !!
const DB_BACKUP_DESIGN_VERSION = 1

export type LocalBackup = {
  id: string
  timestamp: string
  tableName: string
  signature: string
  items: Walk[] | User[] | Plb[] | Message[]
}

export class TripsheetsBackupDexie extends Dexie {
  // The tables
  walksBackup!: Table<LocalBackup>
  usersBackup!: Table<LocalBackup>
  plbsBackup!: Table<LocalBackup>
  messagesBackup!: Table<LocalBackup>

  constructor() {
    try {
      super('TripsheetsDbBackup')

      // Create table indexes
      this.version(DB_BACKUP_DESIGN_VERSION).stores({
        walksBackup: 'id',
        usersBackup: 'id',
        plbsBackup: 'id',
        messagesBackup: 'id',
      })
    } catch (error: any) {
      console.error('Error during Dexie Backup start', error)
      throw new Error(error)
    }
  }
}

export const dbBackup = new TripsheetsBackupDexie()
