import { handleLongList, isLeader } from '../common/utils'
import {
  Box,
  Button,
  Checkbox,
  Flex,
  GridItem,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import { Fragment, useEffect, useState } from 'react'
import { ButtonConfirm } from '../common/UtilComponents'
import { DeleteIcon, SunIcon } from '@chakra-ui/icons'
import { useLiveQuery } from 'dexie-react-hooks'
import { db, User } from '../store/db'
import {
  deleteUser,
  hardDeleteUserFromDb,
  undeleteUser,
  updateUser,
} from '../User/user.model'

type Props = {
  onClick: () => void
}

export const ListAllUsers = (props: Props) => {
  const appStatusDexie = useLiveQuery(() => db.appStatus.toCollection().last())
  const usersDexie = useLiveQuery(() => db.users.toArray())
  const walksDexie = useLiveQuery(() => db.walks.toArray())

  const [amAdmin, setAdmin] = useState(false)
  const [amSuperAdmin, setAmSuperAdmin] = useState(false)
  const [allUsers, setAllUsers] = useState<User[]>([])
  const [filteredUsers, setFilteredUsers] = useState<User[]>([])
  const [haveMore, setHaveMore] = useState(0)

  // Effect to set admin status
  useEffect(() => {
    const user = usersDexie?.find((u) => u?.userId === appStatusDexie?.userId)
    if (!user) return
    setAmSuperAdmin(Boolean(user?.isSuperAdmin))
    setAdmin(Boolean(user?.isAdmin))
  }, [usersDexie, appStatusDexie?.userId])

  // Effect to update the filtered users
  useEffect(() => {
    if (!usersDexie) return

    setAllUsers(
      usersDexie?.sort((a, b) => a.fullName.localeCompare(b.fullName)) || []
    )

    const updatedFilteredUsers = filteredUsers.map((u) => {
      const update = usersDexie.find((i) => i.userId === u.userId)
      return update || ({} as User)
    })
    if (updatedFilteredUsers) setFilteredUsers(updatedFilteredUsers)
  }, [usersDexie])

  const handleScroll = () => {
    return
    // Temp remove the scroll
    //   handleLongList(
    //     allUsers,
    //     filteredUsers,
    //     // @ts-ignore - wants walks[] | users[]
    //     setFilteredUsers,
    //     setHaveMore,
    //     10
    //   )
  }

  const toggleAdminUser = async (userId: string) => {
    const user = usersDexie?.find((u) => u?.userId === userId)
    if (!user) return
    await updateUser({ ...user, isAdmin: !user.isAdmin })
  }

  const toggleSuperAdminUser = async (userId: string) => {
    const user = usersDexie?.find((u) => u?.userId === userId)
    if (!user) return
    await updateUser({ ...user, isSuperAdmin: !user.isSuperAdmin })
  }

  const toggleBlockUser = async (userId: string) => {
    const user = usersDexie?.find((u) => u?.userId === userId)
    if (!user) return
    await updateUser({ ...user, isBlocked: !user.isBlocked })
  }

  const becomeUser = async (userId: string) => {
    const user = usersDexie?.find((u) => u?.userId === userId)
    if (!user) return
    db.appStatus.update('Tripsheets', { userId })
    props.onClick()
  }

  const toggleStatusUser = async (userId: string) => {
    const user = usersDexie?.find((u) => u?.userId === userId)
    if (!user) return
    if (user.status === 'deleted') {
      await undeleteUser(userId)
    } else {
      await deleteUser(userId)
    }
  }

  // Returns as string 0/0 | <nrwalks>/<nrleads>
  const getWalkCount = (userId: string, returnList = false) => {
    const user = usersDexie?.find((u) => u?.userId === userId)
    if (!user) return '?/?'
    // Check if user is used
    const nrWalks = walksDexie?.filter((w) => w.walkers.includes(userId)).length
    const nrLeads = walksDexie?.filter((w) => isLeader(userId, w)).length
    if (!returnList) return `${nrWalks}/${nrLeads}`

    const walks = walksDexie?.filter((w) => w.walkers.includes(userId)) || []
    const walksList = walks.map((w) => {
      const asLeader = isLeader(userId, w) ? ' (leader)' : ''
      return (
        <p>
          {w.title}
          {asLeader}
        </p>
      )
    })
    return <>{walksList}</>
  }

  const toggleVisitorUser = async (userId: string) => {
    const user = usersDexie?.find((u) => u?.userId === userId)
    if (!user) return
    await updateUser({ ...user, isVisitor: !user.isVisitor })
  }

  const hardDeleteUser = async (userId: string) => {
    const user = usersDexie?.find((u) => u?.userId === userId)
    if (user?.status !== 'deleted') return
    await hardDeleteUserFromDb(userId)
  }

  const findUser = (event: any) => {
    const searchFor = event.target.value.toLowerCase()
    const allItems =
      usersDexie?.filter((u: User) => {
        return (
          u.userId.toLowerCase().includes(searchFor) ||
          u.fullName.toLowerCase().includes(searchFor)
        )
      }) || []
    setFilteredUsers(allItems)
    setHaveMore(0)
  }

  return (
    <>
      <Flex alignItems={'center'}>
        <Input
          variant={'outline'}
          placeholder={`Type any part of the user's name or user's id`}
          onChange={findUser}
        />
      </Flex>
      <Box>
        {/*<Box onWheel={handleScroll} onPointerMove={handleScroll}>*/}
        <SimpleGrid columns={amSuperAdmin ? 9 : 4}>
          <GridItem colSpan={2}>
            <Text as={'i'}>
              Name ({filteredUsers.length}/{allUsers.length})
            </Text>
          </GridItem>
          {amSuperAdmin && (
            <GridItem>
              <Text as={'i'}>Active</Text>
            </GridItem>
          )}

          <GridItem>
            <Text as={'i'}>Visitor</Text>
          </GridItem>
          <GridItem>
            <Text as={'i'}>Admin</Text>
          </GridItem>
          {amSuperAdmin && (
            <GridItem>
              <Text as={'i'}>SuperAdmin</Text>
            </GridItem>
          )}
          {amSuperAdmin && (
            <GridItem>
              <Text as={'i'}>Block</Text>
            </GridItem>
          )}
          {amSuperAdmin && <GridItem>{/* Me btn */}</GridItem>}
          {amSuperAdmin && (
            <GridItem>
              <Text as={'i'}>Id</Text>
            </GridItem>
          )}

          {filteredUsers?.map((u) => {
            if (!amSuperAdmin && u.status === 'deleted') return null
            return (
              <Fragment key={u.userId}>
                <GridItem colSpan={2}>
                  <Text
                    as={
                      u.status === 'deleted'
                        ? 'del'
                        : u.userId === appStatusDexie?.userId
                        ? 'b'
                        : undefined
                    }
                  >
                    {u.fullName} {u.isVisitor && <Text as={'sup'}> (v)</Text>}
                    {u.userId === appStatusDexie?.userId && (
                      <Text as={'sup'}>(me)</Text>
                    )}
                  </Text>
                </GridItem>
                {amSuperAdmin && (
                  <GridItem>
                    <Checkbox
                      id={'active-' + u.userId}
                      isChecked={u.status === 'active'}
                      onChange={() => toggleStatusUser(u.userId)}
                      isDisabled={!amSuperAdmin}
                    />
                    <Popover isLazy placement={'right'}>
                      <PopoverTrigger>
                        <Button
                          size={'xs'}
                          variant={'outline'}
                          className={'upABit'}
                        >
                          {getWalkCount(u.userId)}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent>
                        {getWalkCount(u.userId, true)}
                      </PopoverContent>
                    </Popover>
                    {u.status === 'deleted' &&
                      getWalkCount(u.userId) === '0/0' && (
                        <ButtonConfirm
                          icon={<DeleteIcon />}
                          okAction={() => hardDeleteUser(u.userId)}
                          text={`Hard delete ${u.fullName}? Must sync Before & After!`}
                          btnText={''}
                        />
                      )}
                  </GridItem>
                )}
                <GridItem>
                  <Checkbox
                    id={'visitor-' + u.userId}
                    isChecked={u.isVisitor}
                    onChange={() => toggleVisitorUser(u.userId)}
                  />
                </GridItem>
                <GridItem>
                  <Checkbox
                    id={'canSee-' + u.userId}
                    isChecked={u.isAdmin}
                    onChange={() => toggleAdminUser(u.userId)}
                  />
                </GridItem>
                {amSuperAdmin && (
                  <GridItem>
                    <Checkbox
                      id={'admin-' + u.userId}
                      isChecked={u.isSuperAdmin}
                      onChange={() => toggleSuperAdminUser(u.userId)}
                    />
                  </GridItem>
                )}
                {amSuperAdmin && (
                  <GridItem>
                    <Checkbox
                      id={'block-' + u.userId}
                      isChecked={u.isBlocked}
                      onChange={() => toggleBlockUser(u.userId)}
                    />
                  </GridItem>
                )}
                {amSuperAdmin && (
                  <GridItem>
                    <ButtonConfirm
                      icon={<SunIcon />}
                      okAction={() => becomeUser(u.userId)}
                      text={'Become ' + u.fullName}
                      btnText={'Me'}
                    />
                  </GridItem>
                )}
                {amSuperAdmin && (
                  <GridItem>
                    <Text as={'i'} fontSize={'xs'}>
                      {u.userId}
                    </Text>
                  </GridItem>
                )}
              </Fragment>
            )
          })}
          <Text as={'i'} fontSize={'xs'}>
            {haveMore == 0
              ? 'End of users'
              : `Scroll slowly for ${haveMore} more users`}
          </Text>
        </SimpleGrid>
      </Box>
    </>
  )
}
