import differenceInDays from 'date-fns/differenceInDays'
import { db, Tip } from '../store/db'
import { getS3FileContents, getS3Filenames } from '../common/s3Functions'
import packageJson from '../../package.json'

export const getValidTips = async (): Promise<Tip[]> => {
  const appStatusDexie = await db.appStatus.toCollection().last()
  if (!appStatusDexie?.haveInternet) return []

  const remoteTipIds = await getS3Filenames('tip-file')

  // Check all the tips statusses. Display only the first valid one
  const allTips: Tip[] = []
  for (const id of remoteTipIds) {
    const tip = await getS3FileContents('tip-file', id)
    allTips.push(tip)
  }
  const validTips = allTips.filter((tip) => {
    // Tip for this version?
    if (tip.forVersion && tip.forVersion !== packageJson.version) {
      return false
    }

    // Tip beyond seeCount?
    const tipsAdmin = appStatusDexie?.tipsAdmin || {}
    const tipAdmin = tipsAdmin[tip.tipId] || {
      seenCount: 0,
      lastSeenAt: '1900-01-01',
    }
    if (!tip.seeCount) return false
    if (tip.seeCount <= tipAdmin.seenCount) return false

    // Tip enough interval between sees? Randomise the interval
    const interval = differenceInDays(new Date(), new Date(tipAdmin.lastSeenAt))
    if (interval < tip.seeIntervalDays * (1 + Math.random())) return false

    return true
  })
  return validTips
}
