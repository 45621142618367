import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import {
  Box,
  Button,
  Flex,
  IconButton,
  Spinner,
  Text,
  useColorMode,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import {
  BellIcon,
  HamburgerIcon,
  RepeatIcon,
  SunIcon,
  CheckIcon,
  WarningTwoIcon,
  WarningIcon,
  LockIcon,
} from '@chakra-ui/icons'
import { LuFootprints, LuHammer, LuUser } from 'react-icons/lu'
import { debounce } from 'lodash'
import { db, MenuOptions, SyncStatus } from '../store/db'
import { palette, paletteDark } from '../config'
import { AdminTasks } from './AdminTasks'
import { systemListeners } from './Header.events'
import {
  MESSAGE_SEPARATOR,
  syncWithRemote,
  updateSingleSyncProperty,
} from '../common/syncWithRemote'
import './header.css'
import { MessagesIcon } from '../Messages/MessagesIcon'
import { log } from '../common/utils'
import { doTips } from './Header.tips'
import { isServiceWorkerActive } from '../serviceWorker/serviceWorker.helpers'

type Props = {
  stepZoom: () => void
}

export const Header = (props: Props) => {
  const toast = useToast()
  const appStatusDexie = useLiveQuery(() => db.appStatus.toCollection().last())
  const usersDexie = useLiveQuery(() => db.users.toArray())
  const { colorMode, toggleColorMode } = useColorMode()
  const lightBgColor = useColorModeValue(
    palette.lightBackground,
    paletteDark.lightBackground
  )
  const [showLogsIsOpen, setShowLogsIsOpen] = useState(false)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [fullName, setFullName] = useState('No name')
  const [syncText, setSyncText] = useState('Sync')
  // This local syncStatus is faster than the dexie one (and can handle the 'needed' status)
  const [localSyncStatus, setLocalSyncStatus] = useState<SyncStatus>('')

  // Start up stuff ie find our user + color theme
  useEffect(() => {
    // Color theme
    if (
      (appStatusDexie?.isDarkTheme && colorMode === 'light') ||
      (!appStatusDexie?.isDarkTheme && colorMode === 'dark')
    ) {
      toggleColorMode()
    }

    // User stuff
    setFullName('')
    if (!appStatusDexie?.userId) return
    const user = usersDexie?.find((u) => u?.userId === appStatusDexie.userId)
    if (user) {
      if (user.isBlocked) return
      setFullName(`${user?.fullName}`)
    } else {
      navTo(appStatusDexie?.navTo || 'start')
    }

    // Sync stuff
    const syncNeeded =
      appStatusDexie?.sync?.usersLocalUpdates ||
      appStatusDexie?.sync?.walksLocalUpdates
    setLocalSyncStatus(
      syncNeeded
        ? 'needed'
        : appStatusDexie.sync.status === 'done'
        ? 'done'
        : appStatusDexie.sync.status === 'appStart' ||
          appStatusDexie.sync.status === 'error' ||
          appStatusDexie.sync.status === 'bigError'
        ? 'error'
        : ''
    )

    // Tips stuff
    if (appStatusDexie.haveInternet) doTips(toast, appStatusDexie)
  }, [appStatusDexie])

  // Effect to kick off the toast on big error
  useEffect(() => {
    if (appStatusDexie?.sync.status !== 'bigError') return
    const errorMessage = appStatusDexie.sync.message.split(MESSAGE_SEPARATOR)
    log(errorMessage.join('; '), true, { userId: fullName })

    const status = ((errorMessage && errorMessage[0]).toLowerCase() ||
      'error') as 'warning' | 'error'
    const html = (
      <ul>
        {errorMessage.map((e, index) => {
          return index === 0 ? (
            <Fragment key={'bigerror-' + index}></Fragment>
          ) : (
            <li key={'bigerror-' + index}>{e}</li>
          )
        })}
      </ul>
    )
    const toastId = 'bigError'
    !toast.isActive(toastId) &&
      toast({
        id: toastId,
        title: status.toUpperCase(),
        description: html,
        status,
        position: 'top',
        duration: null,
        isClosable: true,
      })
    updateSingleSyncProperty([{ key: 'message', value: '' }])
  }, [appStatusDexie?.sync.status])

  // Effect to check if service worker is running
  useEffect(() => {
    setTimeout(() => {
      if (!isServiceWorkerActive()) {
        log('WSWMIS', true, { userId: fullName })
        const toastId = 'noSw'
        !toast.isActive(toastId) &&
          toast({
            id: toastId,
            title: 'Restart / Reload',
            description:
              'Offline capability is missing - Please restart or reload the app',
            status: 'warning',
            position: 'top',
            duration: null,
            isClosable: true,
          })
      } else {
        log('Offline capability is available')
      }
    }, 10_000)
  }, [])

  // Some general functions
  const navTo = (to: MenuOptions) => {
    db.appStatus.update('Tripsheets', { navTo: to })
  }

  const toggleMenu = (event: any) => {
    setShowLogsIsOpen(false)
    setMenuIsOpen(!menuIsOpen)
  }

  // Logs stuff
  const toggleShowLogs = () => {
    setShowLogsIsOpen(!showLogsIsOpen)
  }

  // Sync handlers
  const allowSync = () => {
    if (appStatusDexie && !appStatusDexie?.haveInternet) return false
    if (appStatusDexie?.sync?.status === 'syncing') return false
    return true
  }
  const startSync = debounce(async () => {
    // Avoid a double sync on double-click using debounce
    if (appStatusDexie?.sync?.status === 'syncing') return
    setLocalSyncStatus('syncing')
    setSyncText('Syncing...')
    await syncWithRemote()
    setSyncText(appStatusDexie?.sync?.btnText || 'Sync')
  }, 300)

  // Online and other listeners ("are we online?") (that augments the service worker)
  systemListeners(appStatusDexie)

  const handleNameClick = () => {
    props.stepZoom()
  }

  const statusIndicator = () => {
    return (
      <Box as={'span'} className={'statusIcon'}>
        {localSyncStatus === 'needed' ? (
          <BellIcon boxSize={4} color={palette.warning} />
        ) : localSyncStatus === 'done' ? (
          <CheckIcon
            boxSize={4}
            color={colorMode === 'dark' ? paletteDark.success : palette.success}
          />
        ) : localSyncStatus === 'error' ? (
          <WarningIcon boxSize={4} color={palette.error} />
        ) : null}
      </Box>
    )
  }

  return (
    <>
      <Box className={'headerContainer'}>
        <Box className={'titleArea'} bg={lightBgColor}>
          <Flex>
            <IconButton
              icon={<HamburgerIcon />}
              size={'sm'}
              ml={2}
              onClick={toggleMenu}
              className={'hamburgerIconButton'}
              aria-label={'Menu icon'}
            />
            <Text
              fontSize={'2xs'}
              className={`clubIdText ${
                colorMode === 'dark' ? 'clubIdTextColor' : ''
              }`}
            >
              {appStatusDexie?.clubId.toUpperCase()}
            </Text>
          </Flex>
          <Box className={'userNameArea'}>
            <Box onClick={handleNameClick}>{fullName}</Box>
            <MessagesIcon />
            {appStatusDexie?.isClosed && (
              <LockIcon ml={2} boxSize={4} color={palette.error} />
            )}
          </Box>
          <Box className={'messages'}>
            {appStatusDexie?.headerMessage || ''}
          </Box>
          {appStatusDexie?.userId && (
            <Box className={'syncContainer'} textAlign={'end'}>
              <Box className={'icons'}>
                {!appStatusDexie?.haveInternet && (
                  <WarningTwoIcon color={palette.warning} />
                )}
                {statusIndicator()}
                <Button
                  leftIcon={
                    appStatusDexie?.sync?.status === 'syncing' ? (
                      <Spinner size="xs" color={palette.action} />
                    ) : (
                      <RepeatIcon />
                    )
                  }
                  isDisabled={!allowSync()}
                  size={'xs'}
                  mr={4}
                  variant="outline"
                  onClick={startSync}
                >
                  {syncText}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        {appStatusDexie?.userId && appStatusDexie?.sync?.message && (
          <Box className={'syncMessageArea'} bg={lightBgColor}>
            <Text as={'i'} fontSize={'xs'} mr={4}>
              {appStatusDexie?.sync?.message}
            </Text>
          </Box>
        )}

        {menuIsOpen && (
          <Box className={'menuArea'}>
            {!appStatusDexie?.userId && (
              <Button
                ml={1}
                leftIcon={<SunIcon />}
                variant="solid"
                onClick={() => navTo('start')}
              >
                Start
              </Button>
            )}
            {appStatusDexie?.userId && (
              <Button
                ml={1}
                leftIcon={<LuFootprints />}
                variant="solid"
                onClick={() => {
                  setMenuIsOpen(false)
                  navTo('walks')
                }}
              >
                Walks
              </Button>
            )}
            {appStatusDexie?.userId && (
              <Button
                ml={1}
                leftIcon={<LuUser />}
                variant="solid"
                onClick={() => {
                  setMenuIsOpen(false)
                  navTo('user')
                }}
              >
                You
              </Button>
            )}
            {appStatusDexie?.userId && (
              <Button
                ml={1}
                size={'xs'}
                leftIcon={<LuHammer />}
                variant="solid"
                onClick={() => {
                  setMenuIsOpen(false)
                  setShowLogsIsOpen(!showLogsIsOpen)
                }}
              >
                Admin
              </Button>
            )}
          </Box>
        )}
      </Box>

      {/* Logs */}
      {showLogsIsOpen && (
        <AdminTasks onClick={toggleShowLogs} onStepZoom={handleNameClick} />
      )}
    </>
  )
}
