import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { db, SyncOptions, User, User as UserType } from '../store/db'
import {
  syncWithRemote,
  updateSingleSyncProperty,
} from '../common/syncWithRemote'
import { useLiveQuery } from 'dexie-react-hooks'
import { palette, paletteDark } from '../config'

import './register.css'
import { ButtonConfirm } from '../common/UtilComponents'
import { LuRotateCw } from 'react-icons/lu'

export const Register = () => {
  const appStatusDexie = useLiveQuery(() => db.appStatus.toCollection().last())
  const usersDexie = useLiveQuery(() => db.users.toArray())
  const [activeUsers, setActiveUsers] = useState<UserType[]>([])
  const [selectedUser, setSelectedUser] = useState<UserType>()
  const [alreadyRegistered, setAlreadyRegistered] = useState<'yes' | 'no'>()

  // Effect on page load: get all users and walks
  useEffect(() => {
    updateSingleSyncProperty([
      { key: 'options', value: { action: 'pull-only' } },
    ]).then(() => syncWithRemote())
  }, [])

  // Effect to create list of active users
  useEffect(() => {
    if (!usersDexie || !usersDexie.length) return
    const users = usersDexie?.filter((u) => isValidUser(u)) || []
    users.sort((a, b) => a.fullName.localeCompare(b.fullName))
    setActiveUsers(users)
  }, [usersDexie])

  const isValidUser = (user: User) => {
    return user.status !== 'deleted' && !user.isBlocked
  }

  const handleAlreadyRegisted = (value: 'yes' | 'no') => {
    setAlreadyRegistered(value)
  }

  const selectUser = (event: any) => {
    const user = activeUsers.find((u) => u.userId === event.target.value)
    setSelectedUser(user)
  }

  const confirmUser = () => {
    if (!selectedUser) return

    db.appStatus.update('Tripsheets', {
      userId: selectedUser.userId,
      navTo: 'walks',
    })
  }

  const joinAs = (value: 'yes' | 'no') => {
    db.appStatus.update('Tripsheets', {
      parameters: value === 'yes' ? 'joinAsMember' : 'joinAsVisitor',
      navTo: 'user',
    })
  }

  return (
    <Box p={4}>
      <Text mt={4} mb={4}>
        We'll start with your details. This should be once only.
      </Text>{' '}
      <Flex alignItems={'center'}>
        <Text mr={2}>Have you registered with us before?</Text>
        <RadioGroup onChange={handleAlreadyRegisted} value={alreadyRegistered}>
          <Stack direction="row" className={'radioGroup'}>
            <Radio value={'yes'}>Yes</Radio>
            <Radio value={'no'} ml={8}>
              No
            </Radio>
          </Stack>
        </RadioGroup>
      </Flex>
      {alreadyRegistered === 'yes' && (
        // TODO get me from stored value, or, select
        // Select me
        <Box mt={4}>
          {!activeUsers?.length && (
            <>
              <Text mb={4}>Can't find the people already registered.</Text>
              {appStatusDexie?.haveInternet ? (
                <Flex>
                  <Text mr={2}>
                    Please try again in 10 seconds. Also check if internet is
                    available.
                  </Text>
                  <ButtonConfirm
                    text={'and reload the app'}
                    btnText={'Try again'}
                    icon={<LuRotateCw />}
                    variant="solid"
                    okAction={() => location.reload()}
                  />
                </Flex>
              ) : (
                <Text>
                  It looks like there isn't an internet connection. Please try
                  again when internet is available.
                </Text>
              )}
            </>
          )}
          {activeUsers?.length > 0 && (
            <>
              <Text mb={4}>
                There are {activeUsers.length} people registered in the app
              </Text>
              <Select
                variant="filled"
                placeholder="Please click here to select yourself"
                onChange={selectUser}
              >
                {activeUsers.map((user) => {
                  return (
                    <option key={user.userId} value={user.userId}>{`${
                      user.fullName
                    }${user.isVisitor ? ' (visitor)' : ''}`}</option>
                  )
                })}
              </Select>
            </>
          )}
          {selectedUser && (
            <Flex mt={4} justifyContent={'end'}>
              <Button
                variant={'solid'}
                colorScheme={palette.action}
                onClick={confirmUser}
              >
                Hello {selectedUser.firstName}, click here to confirm
              </Button>
            </Flex>
          )}
        </Box>
      )}
      {alreadyRegistered === 'no' && (
        <Box mt={4}>
          <Flex alignItems={'center'}>
            <Text mr={2}>Are you a club member?</Text>
            <RadioGroup onChange={joinAs}>
              <Stack direction="row" className={'radioGroup'}>
                <Radio value={'yes'}>Yes</Radio>
                <Radio value={'no'} ml={8}>
                  No
                </Radio>
              </Stack>
            </RadioGroup>
          </Flex>
        </Box>
      )}
    </Box>
  )
}
