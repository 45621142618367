import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  HStack,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useLiveQuery } from 'dexie-react-hooks'
import { InfoOutlineIcon, ViewIcon } from '@chakra-ui/icons'
import differenceInDays from 'date-fns/differenceInDays'
import { LuFootprints } from 'react-icons/lu'
import { db, MenuOptions, SyncOptions, Walk } from '../store/db'
import {
  syncWithRemote,
  updateSingleSyncProperty,
} from '../common/syncWithRemote'

import { palette } from '../config'
import { formatDateTime } from '../common/utils'
import { WalkInfo } from '../Walks/WalkInfo'

export const Start = () => {
  const appStatusDexie = useLiveQuery(() => db.appStatus.toCollection().last())
  const walksDexie = useLiveQuery(() => db.walks.toArray())

  const [showWalkInfoFor, setShowWalkInfoFor] = useState('')
  const [showCurrentWalks, setShowCurrentWalks] = useState(false)
  const [dataLoader, setDataLoader] = useState(false)
  const [currentWalks, setCurrentWalks] = useState<Walk[]>([])

  // Effect to find the started walks
  useEffect(() => {
    if (!showCurrentWalks) return

    // Refresh all data on every viewing
    setDataLoader(true)
    const options: SyncOptions = { action: 'pull-only' }
    updateSingleSyncProperty([{ key: 'options', value: options }]).then(() =>
      syncWithRemote().then(() => {
        const currentWalksDexie =
          // TODO add here select current walks: open, full or started, yd or today
          walksDexie?.filter((w) => {
            if (w.status === 'started') return true
            if (w.status === 'draft') return false

            const diff = differenceInDays(new Date(w.date), new Date())
            if (!isNaN(diff) && Math.abs(diff) <= 2) return true
            return false
          }) || []
        setCurrentWalks(currentWalksDexie)
        setDataLoader(false)
      })
    )
  }, [walksDexie, showCurrentWalks])

  const navToWhere = (navTo: MenuOptions) => {
    db.appStatus.update('Tripsheets', { navTo })
  }

  const registerForWalk = () => {
    db.appStatus.update('Tripsheets', { parameters: 'joinAsMember' })
    navToWhere('register')
  }

  return (
    <>
      <Box>
        <Box ml={8} mt={8}>
          <Text>Hello. What do you want to do?</Text>
          {!appStatusDexie?.haveInternet && (
            <HStack mt={4}>
              <Text as={'b'}>
                You do not have internet. Likely information is outdated
              </Text>
            </HStack>
          )}

          {/* Register */}
          <HStack mt={4}>
            <Button
              leftIcon={<LuFootprints />}
              size={'sm'}
              width={28}
              variant="outline"
              mr={2}
              colorScheme={palette.actionSec}
              onClick={registerForWalk}
            >
              Register
            </Button>
            <Text>for a walk</Text>
          </HStack>

          {/* Current walks */}
          <HStack mt={8}>
            <Button
              leftIcon={<ViewIcon />}
              size={'sm'}
              width={28}
              variant="outline"
              mr={2}
              colorScheme={palette.actionSec}
              onClick={() => setShowCurrentWalks(!showCurrentWalks)}
            >
              See
            </Button>
            <Text>current walks</Text>
          </HStack>
          {showCurrentWalks && (
            <VStack mt={4}>
              {dataLoader ? (
                <Spinner size="md" color={palette.action} />
              ) : currentWalks.length ? (
                currentWalks.map((w) => (
                  <Box key={`w.see-${w.walkId}`}>
                    <Text as={'i'} mr={4}>
                      {w.title}, {w.walkers.length} walkers{' '}
                      {formatDateTime(w.startedAt)}
                    </Text>
                    <Button
                      leftIcon={<InfoOutlineIcon />}
                      size={'sm'}
                      variant="solid"
                      colorScheme={palette.action}
                      onClick={() => setShowWalkInfoFor(w.walkId)}
                    >
                      More
                    </Button>
                    <Divider />
                  </Box>
                ))
              ) : (
                <Text as={'i'}>There are no current or soon walks</Text>
              )}
            </VStack>
          )}
        </Box>
      </Box>

      {/* Walk info panel */}
      {showWalkInfoFor && (
        <WalkInfo
          walkId={showWalkInfoFor}
          onClick={() => setShowWalkInfoFor('')}
        />
      )}
    </>
  )
}
