import {
  Flex,
  GridItem,
  IconButton,
  Input,
  SimpleGrid,
  Spinner,
  Text,
  Textarea,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { ButtonConfirm } from '../common/UtilComponents'
import { AddIcon, AttachmentIcon, DeleteIcon } from '@chakra-ui/icons'
import { Tip } from '../store/db'
import nid from 'nid'
import {
  createS3JsonFile,
  deleteS3File,
  getS3FileContents,
  getS3Filenames,
} from '../common/s3Functions'
import { palette } from '../config'
import { Attachments } from '../Walks/Attachments'

export const ManageTips = () => {
  const toast = useToast()
  const [draftTip, setDraftTip] = useState<Tip>()
  const [allTips, setAllTips] = useState<Tip[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [showAttachmentsFor, setShowAttachmentsFor] = useState('')

  const placeholderColor = useColorModeValue('grey', 'white')

  // On page load, get all tips
  useEffect(() => {
    const fn = async () => {
      setIsLoading(true)
      const tipIds = await getS3Filenames('tip-file')
      const _allTips = []
      for (const id of tipIds) {
        const tip = await getS3FileContents('tip-file', id)
        _allTips.push(tip)
      }
      setAllTips(_allTips)
      setIsLoading(false)
    }
    fn()
  }, [])

  const handleInput = async (event: any) => {
    const field = event.target.id
    let value = event.target.value

    let tip = draftTip
    if (!tip?.tipId) {
      const tipId = nid() // Generate an id
      if (!tipId) return
      tip = {
        tipId: tipId,
        text: '',
        links: '',
        forVersion: '',
        seeCount: 1,
        seeIntervalDays: 30,
      }
    }

    if (field === 'seeCount') value = parseInt(value)
    if (field === 'seeIntervalDays') value = parseInt(value)

    const updatedTip: Tip = {
      ...tip,
      [field]: value,
    }
    setDraftTip(updatedTip)
  }

  const createTip = async () => {
    if (!draftTip?.tipId || !draftTip.text) return
    const result = await createS3JsonFile('tip-file', draftTip.tipId, draftTip)
    toast({
      description: `Status after making tip: ${result.status}. Reload to refresh the list`,
      status: result.status,
    })
    if (result.status === 'success') setDraftTip(undefined)
  }

  const deleteTip = async (tip: Tip) => {
    await deleteS3File('tip-file', tip.tipId)
  }

  return (
    <>
      <SimpleGrid columns={5}>
        <GridItem colSpan={5} mt={4}>
          <Text as={'b'}>Current Tips:</Text>{' '}
          {isLoading && <Spinner size={'xs'} color={palette.action} />}
        </GridItem>
        {allTips.map((tip, index) => {
          return (
            <Fragment key={tip.tipId + index}>
              <GridItem colSpan={2}>{tip.text}</GridItem>
              <GridItem colSpan={1}>{tip.seeCount} time(s)</GridItem>
              <GridItem colSpan={1}>
                {tip.forVersion
                  ? `version ${tip.forVersion} only`
                  : tip.seeCount > 1 && `${tip.seeIntervalDays} days interval`}
              </GridItem>
              <GridItem colSpan={1}>
                <ButtonConfirm
                  text={tip.text.slice(0, 10)}
                  btnText={''}
                  okAction={() => deleteTip(tip)}
                  icon={<DeleteIcon />}
                />
              </GridItem>
              {tip.links && (
                <GridItem colSpan={5}>
                  <Textarea
                    value={tip.links || ''}
                    isReadOnly={true}
                    rows={1}
                  />
                </GridItem>
              )}
            </Fragment>
          )
        })}
        <GridItem colSpan={5}>
          {allTips.length > 1 && (
            <Text as={'i'} fontSize={'xs'}>
              Note: Only 1 relevant tip is displayed at a time
            </Text>
          )}
        </GridItem>
        {/* New tip */}
        <GridItem colSpan={5} mt={4}>
          <Text as={'b'}>Make a new Tip:</Text>
        </GridItem>
        <GridItem colSpan={1}>Tip text</GridItem>
        <GridItem colSpan={4}>
          <Textarea
            id={'text'}
            placeholder="The tip. Use ^^image^^ and ^^link-text^^ to add images and links"
            _placeholder={{ opacity: 0.6, color: placeholderColor }}
            onChange={handleInput}
          />
        </GridItem>
        {/* Links */}
        <GridItem colSpan={1}>Links</GridItem>
        <GridItem colSpan={4}>
          <Textarea
            id={'links'}
            rows={1}
            placeholder="Add links, in sequence, per line. Images must have a width like 'cat.png 50px'"
            _placeholder={{ opacity: 0.6, color: placeholderColor }}
            onChange={handleInput}
          />
        </GridItem>
        {/* See count */}
        <GridItem colSpan={1}>See count</GridItem>
        <GridItem colSpan={4}>
          <Input
            id={'seeCount'}
            defaultValue={1}
            placeholder=""
            _placeholder={{ opacity: 0.6, color: placeholderColor }}
            onChange={handleInput}
          />
        </GridItem>{' '}
        {/* See interval  */}
        <GridItem>
          <Text fontSize={'sm'}>See interval approx days</Text>
        </GridItem>
        <GridItem colSpan={4}>
          <Input
            id={'seeIntervalDays'}
            defaultValue={30}
            placeholder="The interval is randomised to max 2x"
            _placeholder={{ opacity: 0.6, color: placeholderColor }}
            onChange={handleInput}
          />
        </GridItem>
        {/* For version */}
        <GridItem>For version</GridItem>
        <GridItem colSpan={4}>
          <Input
            id={'forVersion'}
            defaultValue={draftTip?.forVersion}
            placeholder="Specify a full version number or leave empty"
            _placeholder={{ opacity: 0.6, color: placeholderColor }}
            onChange={handleInput}
          />
        </GridItem>
        <GridItem colSpan={5}>
          <Flex justify={'end'} mt={4}>
            <IconButton
              icon={<AttachmentIcon />}
              size={'sm'}
              mr={2}
              onClick={() =>
                setShowAttachmentsFor(
                  draftTip?.tipId && showAttachmentsFor !== draftTip.tipId
                    ? draftTip.tipId
                    : ''
                )
              }
              isDisabled={!draftTip?.tipId}
              aria-label={'Attachments for the walk'}
            />
            <ButtonConfirm
              text={'this tip'}
              btnText={'Make'}
              okAction={createTip}
              icon={<AddIcon />}
            />
          </Flex>
        </GridItem>
      </SimpleGrid>

      {/* Attachments panel */}
      {showAttachmentsFor && (
        <Attachments
          tipId={draftTip?.tipId}
          onClick={() => setShowAttachmentsFor('')}
        />
      )}
    </>
  )
}
