import React, { Fragment } from 'react'
import { Tip } from '../store/db'
import { Center, Image, Link } from '@chakra-ui/react'
import { palette } from '../config'

export const tipTextToHtml = (tip: Tip) => {
  const makeLink = (index: number, linkText: string) => {
    // Pick the link from the links list
    const allLinks = tip.links.split('\n')
    const link = allLinks[index]
    if (!link) return <>{linkText}</>

    // Expand it approp
    if (linkText.toLowerCase() === 'image') {
      // Images
      const bits = link.split(' ')
      const size = bits.pop()
      if (size?.slice(-2) !== 'px') return 'WIDTH NOT GIVEN FOR IMAGE'
      const filename = bits.join(' ')
      const root = filename.includes('http') ? '' : '/public/'
      return (
        <Center>
          <img width={size} src={root + filename} loading={'lazy'} />
        </Center>
      )
    } else {
      // Links
      return (
        <Link href={link} color={palette.warning} target={'_blank'} isExternal>
          {linkText}
        </Link>
      )
    }
  }

  // Add in any links
  const expandLinks = (lineText: string) => {
    const bits = lineText.split('^^')
    if (bits.length >= 3 && bits.length % 2 !== 1) return <>{lineText}</> // Gotta have 2 delimiters per link
    let linkIndex = 0
    return (
      <>
        {bits.map((b, index) => {
          if (index % 2 !== 1) return <>{b}</>
          return makeLink(linkIndex++, b)
        })}
      </>
    )
  }

  const lines = tip.text.split('\n')
  const html = (
    <>
      {lines.map((lineText, index) => {
        return (
          <Fragment key={`textitem${index}`}>
            {expandLinks(lineText)}
            <br />
          </Fragment>
        )
      })}
    </>
  )
  return html
}

/*
  Images indicated by ^^image^^ in the text
  Replace that by <img src=... width=... />
  Get relevant data from the links info
 */
const expandImages = (text: string, tip: Tip) => {
  return <>{text}</>
}
