import {
  Box,
  Checkbox,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { palette, paletteDark } from '../config'
import { db, Plb, User } from '../store/db'
import { useLiveQuery } from 'dexie-react-hooks'
import { useEffect, useState } from 'react'
import { givePlbToUser } from './user.model'

export const Emergency = (props: {
  currentUser: Partial<User>
  onChange: (event: any) => Promise<void>
  joinAsVisitor: boolean
}) => {
  const toast = useToast()
  const plbsDexie = useLiveQuery(() => db.plbs.toArray())
  const placeholderColor = useColorModeValue(
    palette.titleSec,
    paletteDark.title
  )
  const [activePlbs, setActivePlbs] = useState<Plb[]>([])
  const [selectedPlbId, setSelectedPlbId] = useState('')

  // Effect to get the plbs
  useEffect(() => {
    if (!plbsDexie) return
    const plbs = plbsDexie.filter((p) => p.status !== 'deleted')
    plbs.sort((a, b) => a.label.localeCompare(b.label))
    setActivePlbs(plbs)
  }, [plbsDexie])

  // Effect to find the selected plb. It must exist or be 'otherPlb' and defaults to ''
  useEffect(() => {
    if (props.currentUser.holdsPlbId === 'otherPlb') {
      setSelectedPlbId('otherPlb')
    } else {
      const heldPlb = activePlbs.find(
        (p) => p.plbId === props.currentUser.holdsPlbId
      )
      setSelectedPlbId(heldPlb?.plbId || '')
    }
  }, [activePlbs, props.currentUser])

  const selectPlb = async (event: any) => {
    const oldHolder = await givePlbToUser(
      event.target.value,
      props.currentUser?.userId
    )
    if (oldHolder) {
      setTimeout(() => {
        // This timeout reqd 'cos plbsDexie comes in late . so setActivePlbs is late
        const plb = activePlbs.find((p) => p.plbId === event.target.value)
        toast({
          title: `PLB (${plb?.label}) removed from ${oldHolder.fullName}`,
          isClosable: true,
          duration: 8_000,
        })
      }, 1000)
    }
  }

  return (
    <>
      {/* Emergency details */}
      <Box alignSelf={'start'} mt={4}>
        <Text as={'b'}>Emergency details:</Text>
      </Box>
      <HStack spacing={4} w={'100%'}>
        <Input
          id={'emergencyPhone'}
          defaultValue={props.currentUser?.emergencyPhone || ''}
          onChange={props.onChange}
          placeholder="Emergency number"
          _placeholder={{ opacity: 0.6, color: placeholderColor }}
        />{' '}
        <Input
          id={'emergencyName'}
          defaultValue={props.currentUser?.emergencyName || ''}
          onChange={props.onChange}
          placeholder="Name or relationship"
          _placeholder={{ opacity: 0.6, color: placeholderColor }}
        />
      </HStack>{' '}
      <HStack spacing={4} w={'100%'}>
        <Input
          id={'emergencyPhone1'}
          defaultValue={props.currentUser?.emergencyPhone1 || ''}
          onChange={props.onChange}
          placeholder="Other emerg. number"
          _placeholder={{ opacity: 0.6, color: placeholderColor }}
        />{' '}
        <Input
          id={'emergencyName1'}
          defaultValue={props.currentUser?.emergencyName1 || ''}
          onChange={props.onChange}
          placeholder="Name or relationship"
          _placeholder={{ opacity: 0.6, color: placeholderColor }}
        />
      </HStack>
      {(props.currentUser?.emergencyPhone ||
        props.currentUser?.emergencyPhone1) && (
        <VStack spacing={4} w={'100%'} ml={4}>
          <Box alignSelf={'start'}>
            <Text>In case of a problem, contact these persons:</Text>
            <RadioGroup
              id={'contactBy'}
              onChange={(v) =>
                props.onChange({ target: { id: 'contactBy', value: v } })
              }
              value={props.currentUser?.contactBy || 'club'}
            >
              <Stack direction="column">
                <Radio value={'club'}>
                  <Text
                    className={
                      props.currentUser?.contactBy !== 'club'
                        ? 'contactByUnselected'
                        : ''
                    }
                  >
                    As soon as practical, by the club
                  </Text>
                </Radio>
                <Radio value={'services'}>
                  <Text
                    className={
                      props.currentUser?.contactBy !== 'services'
                        ? 'contactByUnselected'
                        : ''
                    }
                  >
                    Only by Emergency Services
                  </Text>
                </Radio>
              </Stack>
            </RadioGroup>
          </Box>
        </VStack>
      )}
      {/* Medical form */}
      <HStack spacing={4} w={'100%'} mt={2}>
        <Checkbox
          id={'medicalForm'}
          isChecked={props.currentUser?.medicalForm}
          isInvalid={!props.currentUser?.medicalForm}
          onChange={props.onChange}
        >
          <Text fontSize={'md'} color={palette.actionSec}>
            I have an Emergency Medical Form
          </Text>
        </Checkbox>
      </HStack>
      {props.currentUser?.medicalForm && (
        <HStack spacing={4} w={'100%'}>
          <Input
            id={'medicalFormLocation'}
            ml={6}
            defaultValue={props.currentUser?.medicalFormLocation}
            onChange={props.onChange}
            placeholder="Where can it be found if needed?"
          />
        </HStack>
      )}
      {/* PLB */}
      <HStack spacing={4} w={'100%'} mt={2}>
        <Text fontSize={'md'} as={'b'} color={palette.actionSec}>
          PLB:
        </Text>
        <Select variant="filled" onChange={selectPlb} value={selectedPlbId}>
          <option key={'noPlb'} value={''}>
            Do not carry a PLB
          </option>
          {activePlbs.map((plb) => {
            return (
              <option
                key={plb.plbId}
                value={plb.plbId}
                disabled={plb.plbId === props.currentUser?.holdsPlbId}
              >{`${plb.label}`}</option>
            )
          })}
          <option key={'otherPlb'} value={'otherPlb'}>
            Other or personal PLB
          </option>
        </Select>
      </HStack>
      {/* Private details */}
      {!props.joinAsVisitor && (
        <HStack spacing={4} w={'100%'} h={8}>
          <Checkbox
            id={'isPrivateDetails'}
            isChecked={props.currentUser?.isPrivateDetails}
            isInvalid={!props.currentUser?.isPrivateDetails}
            onChange={props.onChange}
          >
            <Text fontSize={'md'} color={palette.actionSec} mt={6} mb={6}>
              Only share phone details with leader
            </Text>
          </Checkbox>
        </HStack>
      )}
    </>
  )
}
