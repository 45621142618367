import { useEffect, useState } from 'react'
import nid from 'nid'
import {
  Box,
  Checkbox,
  Divider,
  Flex,
  GridItem,
  HStack,
  IconButton,
  Input,
  Select,
  SimpleGrid,
  Text,
  Textarea,
  useColorModeValue,
} from '@chakra-ui/react'
import { CheckIcon, DeleteIcon } from '@chakra-ui/icons'
import { formatISO } from 'date-fns'
import { useLiveQuery } from 'dexie-react-hooks'
import { db, Plb, User as UserType } from '../store/db'
import { palette, paletteDark } from '../config'
import { log } from '../common/utils'
import { syncWithRemote } from '../common/syncWithRemote'
import { ButtonConfirm } from '../common/UtilComponents'
import { addPlb, deletePlb, updatePlb } from '../Plbs/plbs.model'

import 'react-day-picker/dist/style.css'
import './plbs.css'
import { givePlbToUser } from '../User/user.model'

export const AddPlb = (props: { onClick: () => void; editPlbId: string }) => {
  const appStatusDexie = useLiveQuery(() => db.appStatus.toCollection().last())
  const usersDexie = useLiveQuery(() => db.users.toArray())
  const plbsDexie = useLiveQuery(() => db.plbs.toArray())
  const heavyBgColor = useColorModeValue(
    palette.heavyBackground,
    paletteDark.heavyBackground
  )
  const placeholderColor = useColorModeValue('grey', 'white')
  const [addClass, setAddClass] = useState('')
  const [currentUser, setCurrentUser] = useState<Partial<UserType>>()
  const [activeUsers, setActiveUsers] = useState<UserType[]>([])
  const [draftPlb, setDraftPlb] = useState<Plb>()

  // Effect to slide in the panel
  useEffect(() => {
    setAddClass('wide')
  }, [])

  // Effect to find the user
  useEffect(() => {
    if (!appStatusDexie?.userId) return
    const user = usersDexie?.find((u) => u?.userId === appStatusDexie.userId)
    if (!user) return // Sometimes usersDexie comes in late
    setCurrentUser(user)
  }, [appStatusDexie?.userId, usersDexie])

  // Effect to set the draft plb
  useEffect(() => {
    if (!plbsDexie) return
    if (!currentUser?.userId) return
    if (!props.editPlbId) return

    const plb = plbsDexie.find((w) => w.plbId === props.editPlbId)
    setDraftPlb(plb)
  }, [plbsDexie, currentUser?.userId, props.editPlbId])

  // Effect to set the active users
  useEffect(() => {
    const users =
      usersDexie?.filter((u) => u.status !== 'deleted' && !u.isBlocked) || []
    const usersUpdated = users?.map((u) => {
      const fullName = `${u.fullName}${
        draftPlb?.contactUserIds?.includes(u.userId) ? ' CONTACT' : ''
      }${u.isVisitor ? ' (visitor)' : ''}`
      return {
        ...u,
        fullName,
      }
    })
    usersUpdated.sort((a, b) => a.fullName.localeCompare(b.fullName))
    setActiveUsers(usersUpdated)
  }, [usersDexie, draftPlb])

  const handleInput = async (event: any) => {
    if (!appStatusDexie || !currentUser?.userId) return
    const field = event.target.id
    let value = event.target.value
    if (field === 'fileIds') value = value ? value.split(',') : [] // Need an array here
    if (field === 'leaderId') value = value ? value.split(',') : [] // Need an array here
    if (field === 'contactUserIds') {
      // Add or remove the user
      const existingIds = draftPlb?.contactUserIds || []
      if (existingIds.includes(value)) {
        value = existingIds.filter((uId) => uId !== value)
      } else {
        value = existingIds.concat([value])
      }
    }

    // If needed, create a new plb
    let plbId = draftPlb?.plbId
    if (!plbId) {
      plbId = nid() // Generate an id
      if (!plbId) return // This can't happen I think, but keeping ts happy
      await addPlb({
        status: 'draft',
        plbId,
        createdAt: formatISO(new Date()),
        updatedAt: formatISO(new Date()),
        label: '',
        serialNumber: '',
        contactUserIds: [],
        heldByUserId: '',
        notes: '',
      })
      log(`Adding new plb by:`, true, { userId: currentUser?.userId })
    }

    // If adding a holder, update the holders details too
    if (field === 'heldByUserId') {
      const oldHolder = await givePlbToUser(plbId, value)
    }

    // Update the relevant field
    const updatedPlb: Plb = {
      ...(draftPlb as Plb),
      plbId,
      [field]: value,
    }
    await updatePlb(updatedPlb)
    setDraftPlb(updatedPlb)
  }

  const doEditPlb = async (type?: 'delete' | 'ok') => {
    if (!type) return
    if (type === 'delete') {
      deletePlb(draftPlb?.plbId)
    } else if (type === 'ok') {
      const updatedPlb = {
        ...(draftPlb as Plb),
        status: 'inUse' as Plb['status'],
      }
      setDraftPlb(updatedPlb)
      updatePlb(updatedPlb)
    }

    syncWithRemote()
    props.onClick()
  }

  return (
    <>
      <Box className={`makePlbContainer ${addClass}`} bg={heavyBgColor}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Text as={'b'}>{props.editPlbId ? 'Edit' : 'Add'} a PLB</Text>
        </Box>
        <SimpleGrid columns={4} alignItems="center">
          <GridItem>Label</GridItem>
          <GridItem colSpan={3}>
            <Input
              id={'label'}
              defaultValue={draftPlb?.label || ''}
              placeholder="type  s l o w ly !!"
              _placeholder={{ opacity: 0.6, color: placeholderColor }}
              onChange={handleInput}
            />
          </GridItem>

          <GridItem>Serial nr</GridItem>
          <GridItem colSpan={3}>
            <Input
              id={'serialNumber'}
              defaultValue={draftPlb?.serialNumber || ''}
              placeholder=""
              _placeholder={{ opacity: 0.6, color: placeholderColor }}
              onChange={handleInput}
            />
          </GridItem>

          <GridItem>Held by</GridItem>
          <GridItem colSpan={3}>
            <Box>
              <Select
                variant="outlined"
                id={'heldByUserId'}
                value={draftPlb?.heldByUserId || ''}
                onChange={handleInput}
              >
                <option key={'no-holder'} value={''}>{`${
                  draftPlb?.heldByUserId ? 'De-' : ''
                }Select the holder`}</option>
                {(activeUsers || []).map((user) => {
                  return (
                    <option
                      key={user.userId}
                      value={user.userId}
                      disabled={draftPlb?.heldByUserId === user.userId}
                    >{`${user.fullName}`}</option>
                  )
                })}
              </Select>
            </Box>
          </GridItem>

          <GridItem colSpan={4} m={4}>
            <Divider />
          </GridItem>

          <GridItem>
            <Box>Contacts</Box>
          </GridItem>
          <GridItem colSpan={3}>
            {draftPlb?.contactUserIds?.map((contact, index) => {
              const name = usersDexie?.find(
                (user) => user.userId === contact
              )?.fullName
              return (
                <Flex>
                  <Checkbox
                    id={'contactUserIds'}
                    isChecked={true}
                    onChange={() =>
                      handleInput({
                        target: { id: 'contactUserIds', value: contact },
                      })
                    }
                  />
                  <Text ml={2}>
                    {index + 1}. {name}
                  </Text>
                </Flex>
              )
            })}

            <Select
              variant="outlined"
              id={'contactUserIds'}
              value={''}
              onChange={handleInput}
            >
              <option key={'no-holder'} value={''}>{`Select contact #${
                (draftPlb?.contactUserIds?.length || 0) + 1
              }`}</option>
              {(activeUsers || []).map((user) => {
                return (
                  <option
                    key={user.userId}
                    value={user.userId}
                    disabled={draftPlb?.contactUserIds?.includes(user.userId)}
                  >{`${user.fullName}`}</option>
                )
              })}
            </Select>
          </GridItem>

          <GridItem>Notes</GridItem>
          <GridItem mt={2} colSpan={3}>
            <Textarea
              id={'notes'}
              defaultValue={draftPlb?.notes || ''}
              onChange={handleInput}
            />
          </GridItem>
        </SimpleGrid>

        {/* Buttons */}
        <HStack mt={4} spacing={4} justify={'end'} w={'100%'}>
          {/* Delete button */}
          <ButtonConfirm
            text={`plb ${draftPlb?.label || ''}`}
            btnText={'Delete'}
            okAction={() => doEditPlb('delete')}
            icon={<DeleteIcon />}
          />

          <IconButton
            icon={<CheckIcon />}
            size={'sm'}
            colorScheme={'orange'}
            onClick={() => doEditPlb('ok')}
            isDisabled={!draftPlb?.label}
            aria-label={'Save changes'}
          />
        </HStack>
      </Box>
    </>
  )
}
