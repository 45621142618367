export const swRegister = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('./swFunctions.mjs', {
        type: 'module',
      })
      .then(
        (registration) => {
          console.log('Service worker registration successful:', registration)

          // console.log('unregistering...')
          // registration.unregister().then((r) => console.log('res', r))
        },
        (error) => {
          console.error(`Service worker registration failed: ${error}`)
        }
      )
  }
}
