import { formatISO } from 'date-fns'
import nid from 'nid'
import { db, Message, Plb, StandardMessages, Walk } from '../store/db'
import { updateSingleSyncProperty } from '../common/syncWithRemote'
import { formatDateTime, log } from '../common/utils'

export const addMessage = async (message: Message): Promise<void> => {
  await db.messages.add(message)
  await updateSingleSyncProperty([{ key: 'messagesLocalUpdates', value: true }])
}

export const updateMessage = async (message: Message): Promise<void> => {
  await db.messages.update(message.messageId, {
    ...message,
    updatedAt: formatISO(new Date()),
  })
  await updateSingleSyncProperty([{ key: 'messagesLocalUpdates', value: true }])
}

export const deleteMessage = async (id?: string): Promise<void> => {
  if (!id) return
  await db.messages.delete(id)
  await updateSingleSyncProperty([{ key: 'messagesLocalUpdates', value: true }])
}

export const replaceMessagesTable = async (
  newMessages: Message[]
): Promise<void> => {
  try {
    const allMessages = await db.messages.toArray()
    const allMessageIds = allMessages.map((u) => u.messageId)
    await db.messages.bulkDelete(allMessageIds)
    await db.messages.bulkPut(newMessages)
  } catch (e: any) {
    log(`Error while replacing messages table: ${e.message}`)
  }
}

export const sendStandardMessage = async (
  type: StandardMessages,
  id?: string
) => {
  if (!id) return

  const walk = await db.walks.get(id)
  const currentAppStatus = await db.appStatus.get('Tripsheets')
  const usersDexie = await db.users.toArray()
  if (!walk || !currentAppStatus || !usersDexie.length) return

  const messageText =
    type === 'walkEnded'
      ? `Walk ended "${walk.title}" at ${formatDateTime(formatISO(new Date()))}`
      : `Walk cancelled "${walk.title}" at ${formatDateTime(
          formatISO(new Date())
        )}`

  const message: Message = {
    messageId: nid(),
    createdAt: formatISO(new Date()),
    updatedAt: formatISO(new Date()),
    sentAt: formatISO(new Date()),
    status: 'sent',
    fromUserId: currentAppStatus.userId,
    to: 'walk',
    toId: id,
    message: messageText,
  }
  // The msg to the walkers
  addMessage(message)

  if (type === 'walkEnded') {
    // The msg to cansee-ers
    const canSeeErs = usersDexie.filter((u) => u.isAdmin || u.isSuperAdmin)
    for (const user of canSeeErs) {
      addMessage({
        ...message,
        messageId: nid(),
        to: 'user',
        toId: user.userId,
      })
    }

    log(`MSGWEND ${walk.title.slice(0, 10)}`, true, {
      userId: currentAppStatus.userId,
    })
  }
}
