import { Walk, walkStatusses } from '../store/db'
import { Button, GridItem, Input, Select, Text } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { palette } from '../config'

type Props = {
  onClick: () => void
  draftWalk: Walk
  onChange: (event: any) => Promise<void>
  color: string
}

export const MakeWakeAdminSection = (props: Props) => {
  return (
    <>
      <GridItem colSpan={4} mt={4}>
        <Text as={'b'}>For admins:</Text>
      </GridItem>
      <GridItem>Add/Remove</GridItem>
      <GridItem colSpan={3}>
        <Button
          leftIcon={<AddIcon />}
          size={'sm'}
          variant="outline"
          mr={2}
          colorScheme={palette.actionSec}
          onClick={props.onClick}
        >
          Walker
        </Button>
      </GridItem>
      <GridItem>Status</GridItem>
      <GridItem colSpan={3}>
        <Select
          id={'status'}
          variant="outline"
          placeholder=" "
          defaultValue={props.draftWalk.status}
          onChange={props.onChange}
        >
          {walkStatusses.map((status) => {
            return (
              <option
                key={status}
                value={status}
                disabled={props.draftWalk.status === status}
              >
                {status}
              </option>
            )
          })}
        </Select>
      </GridItem>
      <GridItem>Leader Ids</GridItem>
      <GridItem colSpan={3}>
        <Input
          id={'leaderId'}
          defaultValue={props.draftWalk?.leaderId || ''}
          _placeholder={{ opacity: 0.6, color: props.color }}
          placeholder={'Leader Ids'}
          onChange={props.onChange}
        />
      </GridItem>
      <GridItem>Start GPS</GridItem>
      <GridItem colSpan={3}>
        <Input
          id={'startGps'}
          defaultValue={props.draftWalk?.startGps || ''}
          _placeholder={{ opacity: 0.6, color: props.color }}
          placeholder={'Actual start GPS location'}
          onChange={props.onChange}
        />
      </GridItem>
      <GridItem>Started at</GridItem>
      <GridItem colSpan={3}>
        <Input
          id={'startedAt'}
          defaultValue={props.draftWalk?.startedAt || ''}
          _placeholder={{ opacity: 0.6, color: props.color }}
          placeholder={'Actual start time'}
          onChange={props.onChange}
        />
      </GridItem>
      <GridItem>End GPS</GridItem>
      <GridItem colSpan={3}>
        <Input
          id={'endGps'}
          defaultValue={props.draftWalk?.endGps || ''}
          _placeholder={{ opacity: 0.6, color: props.color }}
          placeholder={'Actual end GPS location'}
          onChange={props.onChange}
        />
      </GridItem>
      <GridItem>Ended at</GridItem>
      <GridItem colSpan={3}>
        <Input
          id={'endedAt'}
          defaultValue={props.draftWalk?.endedAt || ''}
          _placeholder={{ opacity: 0.6, color: props.color }}
          placeholder={'Actual end time'}
          onChange={props.onChange}
        />
      </GridItem>
      <GridItem>Attach Ids</GridItem>
      <GridItem colSpan={3}>
        <Input
          id={'fileIds'}
          defaultValue={props.draftWalk?.fileIds || ''}
          _placeholder={{ opacity: 0.6, color: props.color }}
          placeholder={'List of attachment Ids'}
          onChange={props.onChange}
        />
      </GridItem>
    </>
  )
}
