import { FilesMeta, IsClosed, SyncLock, TableName, TipFile } from '../store/db'
import { initializeApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'
import {
  CACHE_SIZE_UNLIMITED,
  getFirestore,
  initializeFirestore,
} from 'firebase/firestore'

import { constants } from '../config'
import { CallResult } from './syncWithRemote'

const fbSetup = () => {
  const firebaseConfig = {
    apiKey: 'AIzaSyBuwabxEyJpeDI2aJn4OkRymSpXRh9KaxQ',
    authDomain: 'tripsheets-bd1e4.firebaseapp.com',
    projectId: 'tripsheets-bd1e4',
    storageBucket: 'tripsheets-bd1e4.appspot.com',
    messagingSenderId: '379383222116',
    appId: '1:379383222116:web:da64dcfe018ab6d62cb471',
    measurementId: 'G-32H321CLLG',
  }
  const fbApp = initializeApp(firebaseConfig)
  let firestore = getFirestore()
  if (!firestore) {
    initializeFirestore(fbApp, {
      cacheSizeBytes: CACHE_SIZE_UNLIMITED,
      // localCache: persistentLocalCache(/*settings*/ {}), // Default
      // localCache:
      // Use multi-tab IndexedDb persistence
      // persistentLocalCache({ tabManager: persistentMultipleTabManager() }),
    })
    firestore = getFirestore()
    console.log('firestore after init', firestore)
  }
  const fbFunctions = getFunctions(fbApp)

  return { fbApp, fbFunctions, firestore }
}

export const fbCreateS3JsonFile = async (
  table: TableName | SyncLock | FilesMeta | IsClosed | TipFile,
  id: string,
  content: any
): Promise<CallResult> => {
  const { fbFunctions } = fbSetup()
  const createS3JsonFile = httpsCallable(fbFunctions, 'createS3JsonFileCall')

  return new Promise((resolve, reject) => {
    try {
      const createS3Result = createS3JsonFile({
        environment: constants.ENVIRONMENT,
        table,
        id,
        content,
      })

      createS3Result.then((r) => {
        resolve(r.data as CallResult)
      })
    } catch (error) {
      console.log('error from fbStoreTableS3Result', error)
      reject(error)
    }
  })
}
