import React, { ReactElement } from 'react'
import { Badge, Flex } from '@chakra-ui/react'
import { palette } from '../config'
import { isLeader } from '../common/utils'
import { User, Walk } from '../store/db'

export const getBadgeFor = (
  walk?: Walk,
  appStatusDexie?: any
): ReactElement => {
  if (!walk || !appStatusDexie) return <></>

  const badge1 =
    walk.status === 'ended' ? (
      <Badge className={'badgeText'}>Completed</Badge>
    ) : isLeader(appStatusDexie?.userId, walk) && walk.status === 'started' ? (
      <Badge colorScheme={palette.info} className={'badgeText'}>
        Leading now
      </Badge>
    ) : walk.status === 'started' ? (
      <Badge colorScheme={palette.info} className={'badgeText'}>
        In progress
      </Badge>
    ) : walk.status === 'draft' ? (
      <Badge className={'badgeText'}>Draft</Badge>
    ) : walk.status === 'expected' ? (
      <Badge className={'badgeText'} size={'xs'}>
        Expected
      </Badge>
    ) : isLeader(appStatusDexie?.userId, walk) ? (
      <Badge colorScheme={palette.info} className={'badgeText'}>
        Leader
      </Badge>
    ) : walk?.status === 'cancelled' ? (
      <Badge colorScheme={palette.warning} className={'badgeText'}>
        Cancelled
      </Badge>
    ) : walk?.status === 'open' &&
      walk?.walkers.includes(appStatusDexie?.userId) ? (
      <Badge colorScheme={palette.warning} className={'badgeText'}>
        Reg'd
      </Badge>
    ) : (
      <></>
    )

  const badge3 =
    walk.category === 'multiday' ? (
      <Badge colorScheme={'yellow'} className={'badgeText'}>
        Multi
      </Badge>
    ) : walk.category === 'campWalk' ? (
      <Badge colorScheme={'yellow'} className={'badgeText'}>
        At Camp
      </Badge>
    ) : walk.category === 'social' ? (
      <Badge colorScheme={'pink'} className={'badgeText'}>
        Social
      </Badge>
    ) : walk.category === 'other' ? (
      <Badge colorScheme={''} className={'badgeText'}>
        Other
      </Badge>
    ) : (
      <></>
    )

  const badge4 = walk.isFull ? (
    <Badge colorScheme={'pink'} className={'badgeText'}>
      Full
    </Badge>
  ) : (
    <></>
  )

  return (
    <Flex>
      {badge1}
      {badge3}
      {badge4}
    </Flex>
  )
}

export const getLeaderBadgeFor = (
  walk?: Walk,
  user?: User,
  myId?: string,
  addAsLeader?: any
): ReactElement => {
  if (!walk || !user?.userId || !myId) return <></>

  // const nrLeaders = typeof walk.leaderId === 'string' ? 1 : walk.leaderId.length

  return (
    <>
      {/* The badge itself */}
      {isLeader(user.userId, walk) && walk.status === 'started' ? (
        <Badge colorScheme={palette.info} className={'badgeText'}>
          Leading now
        </Badge>
      ) : isLeader(user.userId, walk) ? (
        <Badge colorScheme={palette.info} className={'badgeText'}>
          Leader
        </Badge>
      ) : (
        <></>
      )}

      {/*/!* Add/remove button *!/*/}
      {/*{user.userId !== myId || user.isVisitor ? (*/}
      {/*  <>/!* These buttons only for me as a member *!/</>*/}
      {/*) : !isLeader(user.userId, walk) ? (*/}
      {/*  <ButtonConfirm*/}
      {/*    text={'this walk too'}*/}
      {/*    btnText={'I will lead'}*/}
      {/*    okAction={() => addAsLeader(user.userId)}*/}
      {/*    icon={<LuPlusSquare />}*/}
      {/*  />*/}
      {/*) : nrLeaders > 1 ? (*/}
      {/*  <ButtonConfirm*/}
      {/*    text={'leading this walk'}*/}
      {/*    btnText={'Not'}*/}
      {/*    okAction={() => addAsLeader(user.userId, 'remove')}*/}
      {/*    icon={<LuMinusSquare />}*/}
      {/*  />*/}
      {/*) : (*/}
      {/*  <>/!* There must be 1 leader *!/</>*/}
      {/*)}*/}
    </>
  )
}

// Sort walks on a available date: walk end > start > created
export const sortOnADate = (
  walks: Walk[],
  direction: 'asc' | 'desc' = 'desc'
): Walk[] => {
  walks.sort((a, b) => {
    const walkDateA = !isNaN(new Date(a.date).getTime()) ? a.date : undefined
    const walkDateB = !isNaN(new Date(b.date).getTime()) ? b.date : undefined
    const dateA = a.endedAt || a.startedAt || walkDateA || a.createdAt
    const dateB = b.endedAt || b.startedAt || walkDateB || b.createdAt
    return direction === 'asc'
      ? dateA.localeCompare(dateB)
      : dateB.localeCompare(dateA)
  })

  return walks
}

export const getBorderColorClass = (walks?: Walk[], walkId?: string) => {
  // This walk:
  //    - has a belongsTo and an existing parent/camp => colored sidebar
  //    - is a camp and has >=1 child campwalk = > colored sidebar
  //    - otherwise no color
  // For now: no need is for diff colors per camp
  if (!walks || !walkId) return ''
  const walk = walks.find((w) => w.walkId === walkId)
  if (!walk) return ''

  if (walk.category === 'multiday') {
    // Find any belonging camp-walk
    const campWalks = walks.filter((w) => w.belongsToId === walkId)
    if (campWalks.length) return 'sideBorderColor'
  } else if (walk.belongsToId) {
    // Find the parent camp
    const camp = walks.find((w) => w.walkId === walk.belongsToId)
    if (camp) return 'sideBorderColor'
  }
  return ''
}
