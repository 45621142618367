import { db, Message } from '../store/db'

export const getUniqueSentByMessages = async () => {
  const messagesDexie = await db.messages.toArray()
  const appStatusDexie = await db.appStatus.toCollection().last()

  const sentByMeMessages = messagesDexie.filter((m) => {
    return m.status === 'sent' && m.fromUserId === appStatusDexie?.userId
  })

  // Make a unique set: ie specifically filter the 'walk' messages. Only keep the 'To Walk' message
  // and remove the personal messages that are the same (as is the case for end of walk)
  // So: remove the personal (to user) messages that are the same as the walk message
  let uniqueSet: Message[] = []
  const toWalkMessages = sentByMeMessages.filter((m) => m.to === 'walk')
  for (const sentByMe of sentByMeMessages) {
    // Is this message to a user...
    let wantThisOne = true
    if (sentByMe.to === 'user') {
      // ...and has same message as a walk message? Don't want it
      const same = toWalkMessages.find((m) => m.message === sentByMe.message)
      wantThisOne = !same
    }
    if (wantThisOne) uniqueSet.push(sentByMe)
  }

  uniqueSet.sort((a, b) => {
    return b.sentAt.localeCompare(a.sentAt)
  })
  return uniqueSet
}

// Message sent to me (all, walk or just me)
export const getOthersSentToMeMessages = async () => {
  const walksDexie = await db.walks.toArray()
  const messagesDexie = await db.messages.toArray()
  const appStatusDexie = await db.appStatus.toCollection().last()

  if (!walksDexie || !messagesDexie || !appStatusDexie) return []

  const othersSentMessages = messagesDexie.filter((m) => {
    if (m.status !== 'sent') return false

    // Not msgs sent by me
    if (m.fromUserId === appStatusDexie.userId) return false

    // Only msgs for everyone, my walks, or me
    if (m.to === 'all') return true // To everyone
    if (m.to === 'user' && m.toId === appStatusDexie.userId) return true // To me
    if (m.to === 'walk') {
      // Am I on this walk?
      const walk = walksDexie.find((w) => w.walkId === m.toId)
      if (walk?.walkers.includes(appStatusDexie.userId || '')) return true
    }
    return false
  })

  // Remove duplicate messages i.e. same message sent to a walk and to me personally (as admin)
  const toWalkMessages = othersSentMessages.filter((m) => m.to === 'walk')
  let uniqueSet: Message[] = []
  for (const m of othersSentMessages) {
    // Is this a personal message...
    if (m.to !== 'user') {
      uniqueSet.push(m)
      continue
    }

    // ...that is also sent to a walk
    const alreadyHave = othersSentMessages.find(
      (o) => o.to === 'walk' && o.message === m.message
    )
    if (!alreadyHave) uniqueSet.push(m)
  }
  uniqueSet.sort((a, b) => {
    return b.sentAt.localeCompare(a.sentAt)
  })

  uniqueSet.sort((a, b) => {
    return b.sentAt.localeCompare(a.sentAt)
  })

  return uniqueSet
}
