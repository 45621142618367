import React, { useEffect, useState } from 'react'
import {
  AddIcon,
  CloseIcon,
  DeleteIcon,
  EmailIcon,
  InfoOutlineIcon,
  RepeatIcon,
} from '@chakra-ui/icons'
import { palette, paletteDark } from '../config'
import {
  Badge,
  Box,
  Button,
  Flex,
  GridItem,
  Icon,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
} from '@chakra-ui/react'
import './messages.css'
import { ButtonConfirm } from '../common/UtilComponents'
import { formatDateTime, isValidDate, log } from '../common/utils'
import {
  db,
  Message,
  MessageStatus,
  User,
  User as UserType,
  Walk,
  WalkStatus,
} from '../store/db'
import { MakeWalk } from '../Walks/MakeWalk'
import { LuBoxSelect, LuHand } from 'react-icons/lu'
import { differenceInWeeks, format, formatISO } from 'date-fns'
import { DayPicker } from 'react-day-picker'
import { MakeWakeAdminSection } from '../Walks/MakeWalkAdminSection'
import { useLiveQuery } from 'dexie-react-hooks'
import nid from 'nid'
import { addWalk, deleteWalk, updateWalk } from '../Walks/walks.model'
import { addMessage, deleteMessage, updateMessage } from './messages.model'
import { StatusButtons } from './StatusButtons'
import { syncWithRemote } from '../common/syncWithRemote'

type Props = {
  onClick: () => void
  editMessageId: string
}
export const MakeMessage = (props: Props) => {
  const appStatusDexie = useLiveQuery(() => db.appStatus.toCollection().last())
  const usersDexie = useLiveQuery(() => db.users.toArray())
  const walksDexie = useLiveQuery(() => db.walks.toArray())
  const messagesDexie = useLiveQuery(() => db.messages.toArray())
  const heavyBgColor = useColorModeValue(
    palette.heavyBackground,
    paletteDark.heavyBackground
  )
  const [addClass, setAddClass] = useState('')
  const [currentUser, setCurrentUser] = useState<Partial<UserType>>()
  const [draftMessage, setDraftMessage] = useState<Message>()
  const [suitableWalks, setSuitableWalks] = useState<Walk[]>([])
  const [suitableUsers, setSuitableUsers] = useState<User[]>([])

  const toPossibles = [
    { key: 'all', label: 'Everyone' },
    { key: 'walk', label: 'Walkers on a walk' },
    { key: 'user', label: 'One person' },
  ]

  // Effect to slide in the panel
  useEffect(() => {
    setAddClass('wide')
  }, [])

  // Effect to find the user
  useEffect(() => {
    if (!appStatusDexie?.userId) return
    const user = usersDexie?.find((u) => u?.userId === appStatusDexie.userId)
    if (!user) return // Sometimes usersDexie comes in late
    setCurrentUser(user)
  }, [appStatusDexie?.userId, usersDexie])

  // Effect to find the to-edit message
  useEffect(() => {
    if (!messagesDexie) return
    if (!props.editMessageId) return

    const editMessage = messagesDexie.find(
      (m) => m.messageId === props.editMessageId
    )
    setDraftMessage(editMessage)
  }, [messagesDexie, props.editMessageId])

  // Effect to get suitable walks ie open, started, ended, cancelled
  useEffect(() => {
    if (!walksDexie) return
    const suitableStatusses = ['open', 'started', 'ended', 'cancelled']
    const _suitableWalks = walksDexie.filter((w) => {
      const maybe = suitableStatusses.includes(w.status)
      const weeksAgo = differenceInWeeks(new Date(), new Date(w.updatedAt))
      if (['ended', 'cancelled'].includes(w.status)) {
        return maybe && weeksAgo < 2
      }
      return maybe
    })
    setSuitableWalks(_suitableWalks)
  }, [walksDexie])

  // Effect to get suitable users ie not deleted, not blocked
  useEffect(() => {
    if (!usersDexie) return
    const _suitableUsers = usersDexie.filter((u) => {
      return u.status === 'active' && !u.isBlocked
    })
    _suitableUsers.sort((a, b) => a.fullName.localeCompare(b.fullName))

    setSuitableUsers(_suitableUsers)
  }, [usersDexie])

  const handleInput = async (event: any) => {
    if (!appStatusDexie || !currentUser?.userId) return
    const field = event.target.id
    let value = event.target.value

    let messageId = draftMessage?.messageId
    if (!messageId) {
      messageId = nid() // Generate an id
      if (!messageId) return // This can't happen I think, but keeping ts happy
      await addMessage({
        messageId,
        createdAt: formatISO(new Date()),
        updatedAt: formatISO(new Date()),
        sentAt: '',
        status: 'draft',
        fromUserId: currentUser?.userId,
        to: '',
        toId: '',
        message: '',
      })
      log(`MSGNEW`, true, { userId: currentUser?.userId })
    }

    // Update the relevant field
    let updatedMessage: Message
    updatedMessage = {
      ...(draftMessage as Message),
      messageId,
      [field]: value,
    }

    if (field === 'to' && value === 'all') {
      updatedMessage.toId = 'all'
    }

    await updateMessage(updatedMessage)
    setDraftMessage(updatedMessage)
  }

  const haveRecipient = () => {
    return draftMessage?.to && draftMessage?.toId
  }

  const doEditMessage = async (type?: MessageStatus) => {
    let updatedMessage: Message
    switch (type) {
      case 'deleted':
        deleteMessage(draftMessage?.messageId)
        break
      case 'draft':
        updatedMessage = {
          ...(draftMessage as Message),
          status: type,
        }
        setDraftMessage(updatedMessage)
        updateMessage(updatedMessage)
        log(`MSG ${type} ${draftMessage?.message.slice(0, 10)}`, true, {
          userId: currentUser?.userId,
        })
        break
      case 'sent':
        updatedMessage = {
          ...(draftMessage as Message),
          status: type,
          fromUserId: currentUser?.userId || '',
          sentAt: formatISO(new Date()),
        }
        setDraftMessage(updatedMessage)
        updateMessage(updatedMessage)
        log(`Message set to: ${type}`, true, {
          userId: currentUser?.userId,
        })
        break
    }

    syncWithRemote()
    props.onClick()
  }

  return (
    <>
      <Box className={`messagesContainer ${addClass}`} bg={heavyBgColor}>
        {/* Title bar */}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Text as={'b'}>Make a Message</Text>
        </Box>

        <SimpleGrid columns={4} alignItems="center">
          <GridItem>From</GridItem>
          <GridItem colSpan={3}>
            <Input id={'from'} defaultValue={currentUser?.fullName} disabled />
          </GridItem>
          <GridItem>To</GridItem>
          <GridItem colSpan={3}>
            <Select
              id={'to'}
              variant="outlined"
              onChange={handleInput}
              value={draftMessage?.to || ''}
            >
              <option
                key={'no-recipient'}
                value={''}
              >{`Select the recipients`}</option>
              {toPossibles.map((t) => {
                return <option key={t.key} value={t.key}>{`${t.label}`}</option>
              })}
            </Select>
          </GridItem>
          {draftMessage?.to === 'walk' && (
            <>
              <GridItem>Which walk</GridItem>
              <GridItem colSpan={3}>
                <Select
                  id={'toId'}
                  variant="outlined"
                  onChange={handleInput}
                  value={draftMessage?.toId || ''}
                >
                  <option
                    key={'no-walk'}
                    value={''}
                  >{`Select the walk`}</option>
                  {suitableWalks.map((w) => {
                    return (
                      <option
                        key={w.walkId}
                        value={w.walkId}
                      >{`${w.title}`}</option>
                    )
                  })}
                </Select>
              </GridItem>
            </>
          )}{' '}
          {draftMessage?.to === 'user' && (
            <>
              <GridItem>Who</GridItem>
              <GridItem colSpan={3}>
                <Select variant="outlined" id={'toId'} onChange={handleInput}>
                  <option
                    key={'no-user'}
                    value={''}
                  >{`Select the person`}</option>
                  {suitableUsers.map((u) => {
                    return (
                      <option
                        key={u.userId}
                        value={u.userId}
                        disabled={u.userId === currentUser?.userId}
                      >{`${u.fullName}`}</option>
                    )
                  })}
                </Select>
              </GridItem>
            </>
          )}
          <GridItem>Message</GridItem>
          <GridItem colSpan={3}>
            <Textarea
              id={'message'}
              defaultValue={draftMessage?.message || ''}
              onChange={handleInput}
              isDisabled={!haveRecipient()}
            />
          </GridItem>
        </SimpleGrid>

        {/* Buttons */}
        <StatusButtons
          draftMessage={draftMessage}
          doEditMessage={doEditMessage}
        />
      </Box>
    </>
  )
}
