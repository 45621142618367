import React from 'react'
import { useSwipeable } from 'react-swipeable'
import { db } from '../store/db'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  IconButton,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react'
import './help.css'
import { palette } from '../config'
import { CloseIcon } from '@chakra-ui/icons'

type Props = {
  source: string
}

export const Help = (props: Props) => {
  const goBack = () => {
    db.appStatus.update('Tripsheets', { navTo: props.source })
  }

  // Handle swipe to return to the source
  const swipeHandlers = useSwipeable({
    onSwiped: (eventData) => {
      eventData.event.stopPropagation()
      if (eventData.dir === 'Left') goBack()
    },
  })

  const openIndex = ['walks', 'user'].indexOf(props.source)

  if (!props.source) return null
  return (
    // <Box {...swipeHandlers}>
    <Box {...swipeHandlers} className={``}>
      <Box className={'helpTextArea'}>
        {/* My swipe and  return area */}
        <Text as={'b'}>Some help for the screens</Text>
        <VStack>
          <IconButton
            colorScheme={palette.action}
            aria-label="Close messages"
            icon={<CloseIcon />}
            size={'xs'}
            onClick={() => goBack()}
          />
          {/*<Text as={'i'} fontSize={'xx-small'}>*/}
          {/*  Or swipe*/}
          {/*</Text>*/}
        </VStack>{' '}
      </Box>
      <Box className={'helpContainer'}>
        <Accordion allowToggle defaultIndex={openIndex}>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Help for Walks section
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Box className={'helpImageContainer'}>
                <Image
                  objectFit="cover"
                  src="images/help-walks.png"
                  alt="help for walks section"
                />
              </Box>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Help for You section
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Box className={'helpImageContainer'}>
                <Image
                  objectFit="cover"
                  src="images/help-user.png"
                  alt="help for user section"
                />
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </Box>
  )
}
