export const MAX_LOGS = 200

export const isSyncing = {
  // This global specifically to avoid multiple syncs on app start
  isSyncing: false,
}

export const palette = {
  action: 'orange',
  actionSec: 'steelblue',
  info: 'cyan',
  title: 'black',
  titleSec: 'steelblue',
  heavyBackground: 'yellow',
  lightBackground: 'lightyellow',
  standardBackground: 'white',
  error: 'red',
  warning: 'orange',
  success: 'steelblue',
  busy: 'lightgreen',
}

export const paletteDark = {
  action: 'darkorange',
  actionSec: 'steelblue',
  info: 'cyan',
  title: 'white',
  titleSec: 'steelblue',
  heavyBackground: 'darkgreen',
  lightBackground: 'mediumseagreen',
  standardBackground: 'black',
  error: 'red',
  warning: 'orange',
  success: 'darkblue',
  busy: 'lightgreen',
}

export const constants = {
  ENVIRONMENT: import.meta.env.VITE_ENVIRONMENT || '',
  S3_BUCKET_NAME: 'lbbw-tripsheets',
  S3_REGION: 'ap-southeast-2',
  S3_ROOT: `https://lbbw-tripsheets.s3.ap-southeast-2.amazonaws.com/`,
  S3_ACCESS_KEY: import.meta.env.VITE_S3_ACCESS_KEY || '',
  S3_SECRET: import.meta.env.VITE_S3_SECRET || '',
  WEBSITE_URL: 'https://treasurer5384.wixsite.com/2018',
  MESSAGES_DELETE_DAYS: 14,
  MESSAGES_WALKS_DELETE_DAYS: 7,
}
