import React, { useEffect, useState } from 'react'
import {
  Text,
  Box,
  Button,
  useColorModeValue,
  Badge,
  Flex,
} from '@chakra-ui/react'
import { useLiveQuery } from 'dexie-react-hooks'
import { db, Plb } from '../store/db'
import { palette, paletteDark } from '../config'
import { AddIcon, CheckIcon, InfoOutlineIcon } from '@chakra-ui/icons'
import { AddPlb } from './AddPlb'

import './plbs.css'

export const Plbs = () => {
  const usersDexie = useLiveQuery(() => db.users.toArray())
  const plbsDexie = useLiveQuery(() => db.plbs.toArray())

  const [plbs, setPlbs] = useState<Plb[]>([])
  const [showAddPlbIsOpen, setShowAddPlbIsOpen] = useState(false)
  const [editPlbId, setEditPlbId] = useState('')

  useEffect(() => {
    if (!plbsDexie) return

    const activePlbs = plbsDexie.filter((plb) => plb.status !== 'deleted')
    activePlbs.sort((a, b) => a.label.localeCompare(b.label))
    setPlbs(activePlbs)
  }, [plbsDexie])

  return (
    <>
      <Box className={'listItemContainer'} p={0}>
        <Box mt={8} className={'registerPlbsList'}>
          <Text as={'b'}>Club PLBs:</Text>
          {!plbs.length ? null : (
            <Box className={'registerPlbsList'}>
              {/* Each Plb */}
              {plbs.map((plb) => (
                <Box key={plb.plbId}>
                  <Box key={'my-' + plb.plbId} className={'registerPlbsItem'}>
                    <Box display={'flex'} alignItems={'center'}>
                      <Flex mr={2}>
                        {plb.label}
                        <Badge ml={2} className={'badgeText'}>
                          {usersDexie?.find(
                            (u) => u.userId === plb?.heldByUserId
                          )?.fullName || '-'}
                        </Badge>
                        <Badge ml={2} className={'badgeText'}>
                          {plb?.contactUserIds.length}
                        </Badge>
                      </Flex>
                    </Box>
                    <Button
                      leftIcon={<CheckIcon />}
                      size={'sm'}
                      variant="outline"
                      colorScheme={palette.actionSec}
                      onClick={() => {
                        setEditPlbId(plb.plbId)
                        setShowAddPlbIsOpen(!showAddPlbIsOpen)
                      }}
                    >
                      Edit
                    </Button>
                  </Box>
                </Box>
              ))}
            </Box>
          )}

          <Box className={'addPlbContainer'}>
            <Button
              leftIcon={<AddIcon />}
              variant="solid"
              size={'sm'}
              colorScheme={palette.info}
              onClick={() => {
                setEditPlbId('')
                setShowAddPlbIsOpen(!showAddPlbIsOpen)
              }}
            >
              Add a PLB
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Make/Edit a PLB panel */}
      {showAddPlbIsOpen && (
        <AddPlb
          onClick={() => setShowAddPlbIsOpen(!showAddPlbIsOpen)}
          editPlbId={editPlbId}
        />
      )}
    </>
  )
}
