import { useLiveQuery } from 'dexie-react-hooks'
import React, { Fragment, useEffect, useState } from 'react'
import { Badge, Box, Text } from '@chakra-ui/react'
import { EmailIcon } from '@chakra-ui/icons'
import { palette } from '../config'
import { db, Message } from '../store/db'
import { MessagesList } from './MessagesList'
import './messages.css'
import { getOthersSentToMeMessages } from './Messages.logic'

/**
 * Messages - just the icon with indicator badge
 */
export const MessagesIcon = () => {
  const appStatusDexie = useLiveQuery(() => db.appStatus.toCollection().last())
  const [showPanel, setShowPanel] = useState(false)
  const [numberNew, setNumberNew] = useState(0)
  const togglePanel = () => setShowPanel(!showPanel)

  useEffect(() => {
    getOthersSentToMeMessages().then((allOthersSentMesages) => {
      if (!appStatusDexie) return
      const othersMessagesNotSeen = allOthersSentMesages?.filter(
        (m) => m.sentAt > appStatusDexie.messagesSeenTo
      )
      setNumberNew(othersMessagesNotSeen.length)
    })
  }, [appStatusDexie])

  if (!appStatusDexie?.userId) return null
  return (
    <>
      <Box onClick={togglePanel}>
        <EmailIcon ml={2} boxSize={4} />
        {numberNew > 0 && (
          <Badge colorScheme={palette.error}>
            <Text>+{numberNew}</Text>
          </Badge>
        )}
      </Box>

      {showPanel && <MessagesList onClick={() => togglePanel()} />}
    </>
  )
}
