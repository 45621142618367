import { Box, Text } from '@chakra-ui/react'
import { log } from './utils'

export const ErrorScreen = (error: any) => {
  const text =
    error?.error?.message || error?.message || error || '(Unknown error)'
  log('ErrorScreen: ' + text, true)
  return (
    <Box ml={16}>
      <Box>Error during start up. Likely due to an update of the database.</Box>
      <Box>
        Please <Text as={'i'}>fully</Text> close the app and restart a few
        times.
      </Box>
      <Box mt={16}>
        The reported error was:
        <Box ml={16}>{text}</Box>
      </Box>
    </Box>
  )
}
