import React, { ReactElement } from 'react'
import {
  Button,
  Flex,
  IconButton,
  Link,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  UnorderedList,
} from '@chakra-ui/react'
import { palette, constants } from '../config'

type Props = {
  text: string
  btnText: string
  triggerAction?: () => void
  okAction: () => void
  icon: ReactElement
  color?: string
  variant?: 'outline' | 'solid'
  isDisabled?: boolean
}

type PropsLegal = Props & {
  for: 'visitor' | 'member'
}

type PropsDownload = Props & {
  walksUrl?: string
  walkersUrl?: string
}

export const ButtonConfirm = (props: Props) => {
  return (
    <Popover>
      {/* @ts-ignore */}
      {(popoverFunctions: any) => (
        <>
          <PopoverTrigger>
            {props.btnText ? (
              <Button
                leftIcon={props.icon}
                size={'sm'}
                variant={props.variant}
                colorScheme={props.color}
                isDisabled={props.isDisabled}
                onClick={() => props.triggerAction && props.triggerAction()}
              >
                {props.btnText}
              </Button>
            ) : (
              <IconButton
                colorScheme={props.color}
                icon={props.icon}
                size={'sm'}
                onClick={() => props.triggerAction && props.triggerAction()}
                aria-label={'Button action'}
              />
            )}
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody className={'popoverContainer'}>
              <Button
                leftIcon={props.icon}
                size={'sm'}
                colorScheme={palette.action}
                onClick={() => {
                  popoverFunctions.onClose()
                  props.okAction()
                }}
              >
                {props.btnText}
              </Button>
              <Text ml={2}>{props.text} ?</Text>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}

export const ButtonConfirmLegal = (props: PropsLegal) => {
  return (
    <Popover>
      <PopoverTrigger>
        <Button
          leftIcon={props.icon}
          size={'sm'}
          variant={props.variant}
          colorScheme={props.color}
          isDisabled={props.isDisabled}
        >
          {props.btnText}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>
          <Text as={'b'}>Risk waiver basics for {props.for}s</Text>
        </PopoverHeader>
        {props.for === 'visitor' ? (
          <PopoverBody className={'popoverContainer'}>
            <UnorderedList>
              <ListItem>
                I acknowledge all risks of death, injury, loss, damage or due to
                adverse weather
              </ListItem>
              <ListItem>
                I understand the scope and I believe I am capable for this walk
              </ListItem>
              <ListItem>
                I am carrying all appropriate food, drink and equipment
              </ListItem>
              <ListItem>I accept all instructions from the leader</ListItem>
              <ListItem>
                <Link
                  target="_blank"
                  href={`${constants.WEBSITE_URL}/tripsheets-legal-visitors`}
                >
                  Read the full text
                </Link>
              </ListItem>
            </UnorderedList>
          </PopoverBody>
        ) : (
          <PopoverBody className={'popoverContainer'}>
            <UnorderedList>
              <ListItem>I am responsible for my own safety</ListItem>
              <ListItem>I have the required equipment with me</ListItem>
              <ListItem>I acknowledge all risks</ListItem>
              <ListItem>
                <Link
                  target="_blank"
                  href={`${constants.WEBSITE_URL}/tripsheets-legal-members`}
                >
                  Read the full text
                </Link>
              </ListItem>
            </UnorderedList>
          </PopoverBody>
        )}
        <PopoverFooter className={'popoverContainer'}>
          <Button
            leftIcon={props.icon}
            size={'sm'}
            colorScheme={palette.action}
            onClick={props.okAction}
          >
            {'I agree'}
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  )
}

export const ButtonConfirmDownload = (props: PropsDownload) => {
  return (
    <Popover>
      <PopoverTrigger>
        <Button
          leftIcon={props.icon}
          size={'xs'}
          height={8}
          ml={2}
          variant={props.variant}
          colorScheme={props.color}
          isDisabled={props.isDisabled}
          onClick={props.okAction}
        >
          {props.btnText}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody className={'popoverContainer'}>
          <Flex direction="column">
            <Link href={props.walksUrl} download={'walks.csv'}>
              <Text>Download all walks</Text>
            </Link>
            <Link href={props.walkersUrl} download={'walkers.csv'}>
              <Text>Download all walkers</Text>
            </Link>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
