import React, { useEffect, useState } from 'react'
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  GridItem,
  IconButton,
  SimpleGrid,
  Text,
  Textarea,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  AttachmentIcon,
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  InfoOutlineIcon,
} from '@chakra-ui/icons'
import { useLiveQuery } from 'dexie-react-hooks'
import { useSwipeable } from 'react-swipeable'
import { db, User, Walk } from '../store/db'
import { palette, paletteDark } from '../config'
import { addLeaderFor, deleteWalk } from './walks.model'
import {
  formatDateTime,
  formatMultiDateTime,
  getWalkLeaderNames,
  isLeader,
} from '../common/utils'
import { Attachments } from './Attachments'
import { getBadgeFor, getLeaderBadgeFor } from './Walks.helpers'

import './walks.css'

type Props = {
  walkId: string
  onClick: () => void
  editWalk?: (id?: string) => void
}
export const WalkInfo = (props: Props) => {
  const appStatusDexie = useLiveQuery(() => db.appStatus.toCollection().last())
  const usersDexie = useLiveQuery(() => db.users.toArray())
  const walksDexie = useLiveQuery(() => db.walks.toArray())
  const plbsDexie = useLiveQuery(() => db.plbs.toArray())
  const heavyBgColor = useColorModeValue(
    palette.heavyBackground,
    paletteDark.heavyBackground
  )
  const [addClass, setAddClass] = useState('')
  const [walk, setWalk] = useState<Walk>()
  const [showUserInfoFor, setShowUserInfoFor] = useState('')
  const [showAttachmentsFor, setShowAttachmentsFor] = useState('')
  const [showPlbInfo, setShowPlbInfo] = useState(false)
  const [amSuperAdmin, setAmSuperAdmin] = useState(false)
  const [amCanSee, setCanSee] = useState(false)

  // Handle swipe to close
  const swipeHandlers = useSwipeable({
    onSwiped: (eventData) => {
      eventData.event.stopPropagation()
      if (eventData.dir === 'Right') props.onClick()
    },
  })

  // Effect to slide in the panel
  useEffect(() => {
    setAddClass('wide')
  }, [])

  // Effect to get the walk info
  useEffect(() => {
    if (!props.walkId) return
    const walkDexie = walksDexie?.find((w) => w.walkId === props.walkId)
    if (walkDexie) setWalk(walkDexie)
  }, [walksDexie, props.walkId])

  // Effect to get the admin status
  useEffect(() => {
    const user = usersDexie?.find((u) => u?.userId === appStatusDexie?.userId)
    setAmSuperAdmin(Boolean(user?.isSuperAdmin))
    setCanSee(Boolean(user?.isAdmin))
  }, [usersDexie, appStatusDexie?.userId])

  const deleteThisWalk = async () => {
    await deleteWalk(walk?.walkId)

    props.onClick()
  }

  const showPrivate = (
    walkerId: string,
    what: 'phone' | 'address' = 'phone'
  ) => {
    if (!appStatusDexie) return false
    if (!usersDexie) return false

    // Is this walker ok with showing phone?
    if (what === 'phone') {
      const walker = usersDexie.find((u) => u.userId === walkerId)
      if (!walker?.isPrivateDetails) return true
    }

    // Is the current user the leader or is the current user the walker?
    return (
      isLeader(appStatusDexie.userId, walk) ||
      appStatusDexie.userId === walkerId ||
      amCanSee ||
      amSuperAdmin
    )
  }

  const addAsLeader = (userId: string, action?: 'remove') => {
    if (!walk || !userId) return
    addLeaderFor(walk.walkId, userId, action)
  }

  const getPlbInfo = (plbId: string) => {
    if (plbId === 'otherPlb') {
      return <Box>Carries a personal PLB</Box>
    }

    const plb = plbsDexie?.find((p) => p.plbId === plbId)
    if (!plb) return <></>
    return (
      <Box>
        Carries PLB: {plb.label}
        <IconButton
          icon={<InfoOutlineIcon />}
          size={'sm'}
          ml={2}
          onClick={() => setShowPlbInfo(!showPlbInfo)}
          aria-label={'Info about the PLB'}
        />
        {showPlbInfo && (
          <Box>
            PLB Contacts:{' '}
            {plb.contactUserIds.map((cId) => {
              const user = usersDexie?.find((u) => u.userId === cId)
              return (
                <Box key={'contact-' + cId}>
                  {'- '}
                  {user?.fullName || ''}, ph: {user?.phone || ''}
                  {user?.isPrivateDetails && ' (Is private!)'}
                </Box>
              )
            })}
          </Box>
        )}
      </Box>
    )
  }

  return (
    <Box {...swipeHandlers}>
      <Box className={`makeWalkContainer ${addClass}`} bg={heavyBgColor}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Tooltip label={`Id: ${walk?.walkId}`} placement={'right'}>
            <Text as={'b'} color={palette.titleSec}>
              Walk Info
            </Text>
          </Tooltip>

          {/* Close icon */}
          <IconButton
            colorScheme={palette.action}
            aria-label="Close walk info"
            icon={<CloseIcon />}
            size={'xs'}
            onClick={props.onClick}
          />
        </Box>

        <SimpleGrid columns={6} alignItems="center">
          <GridItem as={'i'} fontSize={'sm'} colSpan={1}>
            To
          </GridItem>
          <GridItem colSpan={5}>
            <Flex>
              <Text as={walk?.status === 'cancelled' ? 's' : undefined}>
                {walk?.title}
              </Text>
              {getBadgeFor(walk, appStatusDexie)}
            </Flex>
          </GridItem>
          {walk?.category === 'campWalk' && walk?.belongsToId && (
            <>
              <GridItem as={'i'} fontSize={'sm'} colSpan={1}>
                Camp
              </GridItem>
              <GridItem colSpan={5}>
                {walksDexie?.find((w) => w.walkId === walk?.belongsToId)
                  ?.title || ''}
              </GridItem>
            </>
          )}
          <GridItem as={'i'} fontSize={'sm'} colSpan={1}>
            Leadr
          </GridItem>
          <GridItem colSpan={5}>
            {getWalkLeaderNames(walk, usersDexie)}
          </GridItem>
          <GridItem as={'i'} fontSize={'sm'} colSpan={1}>
            Date
          </GridItem>
          <GridItem colSpan={5}>
            {walk?.category !== 'multiday'
              ? formatDateTime(walk?.date, 'EEEE d MMM yyyy')
              : formatMultiDateTime(walk?.date, walk?.dateTo)}
          </GridItem>
          <GridItem as={'i'} fontSize={'sm'} colSpan={1}>
            Meet
          </GridItem>
          <GridItem colSpan={5}>{walk?.meetPoint}</GridItem>
          <GridItem as={'i'} fontSize={'sm'} colSpan={1}></GridItem>
          <GridItem colSpan={5}>{walk?.meetTime}</GridItem>
          <GridItem as={'i'} fontSize={'sm'} colSpan={1}>
            Attach.
          </GridItem>
          <GridItem colSpan={3}>
            {walk?.fileIds?.length ? (
              <Flex alignItems={'center'}>
                <Badge mr={2}>{walk?.fileIds?.length}</Badge>
                {walk?.fileIds?.length === 1 ? 'file' : 'files'}
              </Flex>
            ) : (
              '-'
            )}
          </GridItem>
          <GridItem colSpan={2}>
            <Flex justifyContent={'flex-end'} alignItems={'center'}>
              {!appStatusDexie?.haveInternet && (
                <Text as={'i'} fontSize={'sm'} mr={2}>
                  No Internet
                </Text>
              )}
              <IconButton
                icon={<AttachmentIcon />}
                size={'sm'}
                onClick={() =>
                  setShowAttachmentsFor(
                    walk && showAttachmentsFor !== walk.walkId
                      ? walk.walkId
                      : ''
                  )
                }
                isDisabled={!appStatusDexie?.haveInternet}
                aria-label={'Attachments for the walk'}
              />
            </Flex>
          </GridItem>
          {walk?.grade && (
            <>
              <GridItem as={'i'} fontSize={'sm'} colSpan={1}>
                Grade
              </GridItem>
              <GridItem colSpan={5}>{walk?.grade}</GridItem>
            </>
          )}
          {walk?.notes && (
            <>
              <GridItem as={'i'} fontSize={'sm'} colSpan={1}>
                Notes
              </GridItem>
              <GridItem colSpan={5}>
                <Textarea
                  isReadOnly={true}
                  rows={4}
                  value={walk?.notes || ''}
                />
              </GridItem>
            </>
          )}
          <GridItem colSpan={6} h={8} display={'flex'} alignItems={'center'}>
            <Divider />
          </GridItem>
          <GridItem colSpan={6} alignSelf="flex-start">
            <Text as={'b'} color={palette.titleSec}>
              {walk?.walkers.length} Walker
              {walk?.walkers.length && walk?.walkers.length > 1 ? 's' : ''}
            </Text>
          </GridItem>
          <GridItem colSpan={6}>
            {walk?.walkers.map((walkerId, index) => {
              const walker =
                usersDexie?.find((u) => u.userId === walkerId) || ({} as User)
              let emergText: string | string[] = [walker.emergencyPhone]
              if (walker.emergencyName) emergText.push(walker.emergencyName)
              emergText = showPrivate(walkerId)
                ? emergText.join(', ')
                : '******'

              let emergText1: string | string[] = [walker.emergencyPhone1]
              if (walker.emergencyName1) emergText1.push(walker.emergencyName1)
              emergText1 = showPrivate(walkerId)
                ? emergText1.join(', ')
                : '******'

              return (
                <Box key={`walker-info-${walkerId}-${index}`}>
                  <Box display={'flex'} alignItems={'center'}>
                    <IconButton
                      icon={<InfoOutlineIcon />}
                      size={'sm'}
                      mr={2}
                      onClick={() => {
                        setShowPlbInfo(false)
                        setShowUserInfoFor(
                          showUserInfoFor !== walkerId ? walkerId : ''
                        )
                      }}
                      aria-label={'Info about the walker'}
                    />
                    <Flex key={'walker-' + walkerId} justifyContent={'center'}>
                      <Text
                        as={
                          walker.status === 'deleted'
                            ? 'del'
                            : walker.userId === appStatusDexie?.userId
                            ? 'b'
                            : undefined
                        }
                      >
                        {walker.fullName}
                        {walker.isVisitor && <Text as={'sup'}> (v)</Text>}
                      </Text>{' '}
                      {getLeaderBadgeFor(
                        walk,
                        walker,
                        appStatusDexie?.userId,
                        addAsLeader
                      )}
                      {walker.holdsPlbId && (
                        <Badge
                          colorScheme={palette.warning}
                          className={'badgeText'}
                        >
                          PLB
                        </Badge>
                      )}
                    </Flex>
                  </Box>
                  {showUserInfoFor === walkerId && (
                    <Box className={'walkerDetails'}>
                      <Box>
                        Phone: {showPrivate(walkerId) ? walker.phone : '******'}
                      </Box>
                      <Box>Emerg: {emergText}</Box>
                      {emergText1 && <Box>Emerg: {emergText1}</Box>}
                      {showPrivate(walkerId, 'address') && walker.address && (
                        <Box>
                          Addres: {walker.address.replaceAll('\n', ', ')}{' '}
                        </Box>
                      )}
                      {(emergText || emergText1) && (
                        <Box>
                          If a problem: Contact by{' '}
                          {walker.contactBy === 'services'
                            ? 'Services only'
                            : 'the club'}
                        </Box>
                      )}
                      {walker.medicalForm ? (
                        <Box>
                          Does have a medical form.
                          {walker.medicalFormLocation
                            ? ` Located: ${walker.medicalFormLocation}`
                            : ''}
                        </Box>
                      ) : (
                        <Box>No medical form</Box>
                      )}
                      {walker.holdsPlbId && getPlbInfo(walker.holdsPlbId)}
                    </Box>
                  )}
                </Box>
              )
            })}
          </GridItem>
          <GridItem colSpan={6} h={8} display={'flex'} alignItems={'center'}>
            <Divider />
          </GridItem>
          <GridItem as={'i'} fontSize={'sm'} colSpan={6}>
            About the walk itself:
          </GridItem>
          <GridItem as={'i'} fontSize={'sm'} colSpan={6}>
            Start:
          </GridItem>
          <GridItem as={'i'} fontSize={'sm'} colSpan={6}>
            {walk?.startPoint}
            {walk?.startTime ? `, ${walk.startTime}` : ''}
          </GridItem>
          <GridItem as={'i'} fontSize={'sm'} colSpan={6}>
            {walk?.startGps}
          </GridItem>
          <GridItem as={'i'} fontSize={'sm'} colSpan={6}>
            {formatDateTime(walk?.startedAt)}
          </GridItem>
          <GridItem as={'i'} fontSize={'sm'} colSpan={6} mt={2}>
            End:
          </GridItem>
          <GridItem as={'i'} fontSize={'sm'} colSpan={6}>
            {walk?.endGps}
          </GridItem>
          <GridItem as={'i'} fontSize={'sm'} colSpan={6}>
            {formatDateTime(walk?.endedAt)}
          </GridItem>
          {/* Extra actions */}
          <GridItem as={'i'} fontSize={'sm'} colSpan={3}>
            {/* For admins: Delete or edit this walk */}
            <Flex mt={4}>
              {amSuperAdmin && (
                <Box ml={1}>
                  {props.editWalk && (
                    <Button
                      leftIcon={<CheckIcon />}
                      size={'sm'}
                      variant="outline"
                      colorScheme={palette.actionSec}
                      onClick={() => {
                        props.onClick()
                        props.editWalk && props.editWalk(walk?.walkId)
                      }}
                    >
                      Edit
                    </Button>
                  )}
                </Box>
              )}
            </Flex>
          </GridItem>
        </SimpleGrid>
      </Box>

      {/* Attachments panel */}
      {showAttachmentsFor && (
        <Attachments
          walkId={walk?.walkId}
          onClick={() => setShowAttachmentsFor('')}
        />
      )}
    </Box>
  )
}
