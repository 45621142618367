import {
  Button,
  Checkbox,
  GridItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Text,
  useToast,
} from '@chakra-ui/react'
import { Fragment, useEffect, useState } from 'react'
import { ButtonConfirm } from '../common/UtilComponents'
import { AddIcon, DeleteIcon, SunIcon } from '@chakra-ui/icons'
import { useLiveQuery } from 'dexie-react-hooks'
import { db, Walk } from '../store/db'
import { sortOnADate } from '../Walks/Walks.helpers'
import { formatDateTime, isLeader } from '../common/utils'
import { updateWalk } from '../Walks/walks.model'

export const CombineUsers = () => {
  const toast = useToast()
  const appStatusDexie = useLiveQuery(() => db.appStatus.toCollection().last())
  const usersDexie = useLiveQuery(() => db.users.toArray())
  const walksDexie = useLiveQuery(() => db.walks.toArray())

  const [amSuperAdmin, setAmSuperAdmin] = useState(false)
  // const [amAdmin, setAdmin] = useState(false)
  const [primaryId, setPrimaryId] = useState('')
  const [secId, setSecId] = useState('')
  const [latestWalkPrimary, setLatestWalkPrimary] = useState<Walk>()
  const [latestWalkSec, setLatestWalkSec] = useState<Walk>()

  const allUsers =
    usersDexie?.sort((a, b) => a.fullName.localeCompare(b.fullName)) || []

  // Effect to get the admin status
  useEffect(() => {
    const user = usersDexie?.find((u) => u?.userId === appStatusDexie?.userId)
    if (!user) return // Sometimes usersDexie comes in late
    setAmSuperAdmin(Boolean(user?.isSuperAdmin))
    // setAdmin(Boolean(user?.isAdmin))
  }, [usersDexie, appStatusDexie?.userId])

  const toggleChecked = (userId: string) => {
    if (userId === primaryId) {
      setPrimaryId('')
      setLatestWalkPrimary(undefined)
    } else if (userId === secId) {
      setSecId('')
      setLatestWalkSec(undefined)
    } else if (!primaryId) {
      setPrimaryId(userId)
      setLatestWalk(userId, 'primary')
    } else if (!secId) {
      setSecId(userId)
      setLatestWalk(userId, 'sec')
    }
  }

  const setLatestWalk = (userId: string, target: 'primary' | 'sec') => {
    const userWalks = walksDexie?.filter((w) => w.walkers.includes(userId))
    if (!userWalks || !userWalks.length) return
    const walksSorted = sortOnADate(userWalks)
    if (target === 'primary') setLatestWalkPrimary(walksSorted[0])
    if (target === 'sec') setLatestWalkSec(walksSorted[0])
  }

  const getWalkCount = (userId: string, returnList = false) => {
    const user = usersDexie?.find((u) => u?.userId === userId)
    if (!user) return '?/?'
    // Check if user is used
    const nrWalks = walksDexie?.filter((w) => w.walkers.includes(userId)).length
    const nrLeads = walksDexie?.filter((w) => isLeader(userId, w)).length
    if (!returnList) return `${nrWalks}/${nrLeads}`

    const walks = walksDexie?.filter((w) => w.walkers.includes(userId)) || []
    const walksList = walks.map((w) => {
      const asLeader = isLeader(userId, w) ? ' (leader)' : ''
      return (
        <p>
          {w.title}
          {asLeader}
        </p>
      )
    })
    return <>{walksList}</>
  }

  const doCombineUsers = async () => {
    const secWalks = walksDexie?.filter((w) => w.walkers.includes(secId))
    if (!secWalks || !secWalks.length) {
      toast({
        title: `No walks to combine`,
        duration: 3000,
        isClosable: true,
      })
      return
    }

    for (const w of secWalks) {
      // Am i the leader?
      let newLeaders: string[] = []
      if (typeof w.leaderId === 'string') {
        newLeaders = w.leaderId === secId ? [primaryId] : [w.leaderId]
      } else if (Array.isArray(w.leaderId)) {
        const pos = w.leaderId.indexOf(secId)
        if (pos > -1) {
          w.leaderId.splice(pos, 1, primaryId)
          newLeaders = [...w.leaderId]
        }
      } else {
        toast({
          title: `The leaders array looks to be wrong: ${w.leaderId}`,
          status: 'error',
          duration: 8000,
          isClosable: true,
        })
        break
      }
      w.leaderId = newLeaders

      // Update the walkers list
      const pos = w.walkers.indexOf(secId)
      if (pos > -1) {
        w.walkers.splice(pos, 1, primaryId)
        w.walkers = [...new Set(w.walkers)]
      }

      // Update dexie
      await updateWalk(w)

      setLatestWalkPrimary(undefined)
      setLatestWalkSec(undefined)
    }

    toast({
      title: `Combined. Sync first! Then delete the secondary through 'Show Users'`,
      duration: 8000,
      isClosable: true,
    })
  }

  return (
    <SimpleGrid columns={6}>
      <GridItem>
        <Text as={'i'}>Name ({allUsers.length})</Text>
      </GridItem>
      <GridItem>
        <Text as={'i'}>Active</Text>
      </GridItem>
      <GridItem>
        <Text as={'i'}>Select</Text>
      </GridItem>
      <GridItem>
        <Text as={'i'}>Status</Text>
      </GridItem>{' '}
      <GridItem colSpan={2}>
        <Text as={'i'}>Latest walk</Text>
      </GridItem>
      {/* List */}
      {allUsers?.map((u) => {
        if (!amSuperAdmin && u.status === 'deleted') return null
        if (primaryId && secId && u.userId !== primaryId && u.userId !== secId)
          return null
        return (
          <Fragment key={u.userId}>
            <GridItem>
              <Text
                as={
                  u.status === 'deleted'
                    ? 'del'
                    : u.userId === appStatusDexie?.userId
                    ? 'b'
                    : undefined
                }
              >
                {u.fullName} {u.isVisitor && <Text as={'sup'}> (v)</Text>}
                {u.userId === appStatusDexie?.userId && (
                  <Text as={'sup'}>(me)</Text>
                )}
              </Text>
            </GridItem>
            <GridItem>
              <Checkbox
                id={'active-' + u.userId}
                isChecked={u.status === 'active'}
                isDisabled={true}
              />
              <Popover isLazy placement={'right'}>
                <PopoverTrigger>
                  <Button size={'xs'} variant={'outline'} className={'upABit'}>
                    {getWalkCount(u.userId)}
                  </Button>
                </PopoverTrigger>
                <PopoverContent>{getWalkCount(u.userId, true)}</PopoverContent>
              </Popover>
            </GridItem>
            <GridItem>
              <Checkbox
                id={'selected-' + u.userId}
                isChecked={u.userId === primaryId || u.userId === secId}
                onChange={() => toggleChecked(u.userId)}
              />
            </GridItem>
            <GridItem>
              {u.userId === primaryId && 'Primary'}
              {u.userId === secId && 'Secondary'}
            </GridItem>
            <GridItem colSpan={2}>
              {u.userId === primaryId &&
                latestWalkPrimary &&
                `"${latestWalkPrimary?.title}" on ${formatDateTime(
                  latestWalkPrimary?.date,
                  'dd-MM-yyyy'
                )}`}{' '}
              {u.userId === secId &&
                latestWalkSec &&
                `"${latestWalkSec?.title}" on ${formatDateTime(
                  latestWalkSec?.date,
                  'dd-MM-yyyy'
                )}`}
            </GridItem>
          </Fragment>
        )
      })}
      {/* Buttons */}
      <GridItem colSpan={6}>
        <ButtonConfirm
          text={'the Secondary walks into Primary'}
          btnText={'Combine'}
          okAction={doCombineUsers}
          icon={<AddIcon />}
        />
      </GridItem>
    </SimpleGrid>
  )
}
