import React, { Fragment, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  GridItem,
  IconButton,
  Select,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { AddIcon, CheckIcon, SunIcon } from '@chakra-ui/icons'
import { useLiveQuery } from 'dexie-react-hooks'
import {
  db,
  User as UserType,
  Walk,
  WalkerChanges,
  WalkerChangeType,
} from '../store/db'
import { palette, paletteDark } from '../config'
import { updateWalk } from './walks.model'
import './walks.css'
import { syncWithRemote } from '../common/syncWithRemote'
import { AddNewWalker } from './AddNewWalker'
import { isLeader } from '../common/utils'

type Props = {
  walkId: string
  onClick: () => void
}

export const WalkersList = (props: Props) => {
  const usersDexie = useLiveQuery(() => db.users.toArray())
  const walksDexie = useLiveQuery(() => db.walks.toArray())
  const heavyBgColor = useColorModeValue(
    palette.heavyBackground,
    paletteDark.heavyBackground
  )
  const [addClass, setAddClass] = useState('')
  const [walk, setWalk] = useState<Walk>()
  const [showAddNewWalker, setShowAddNewWalker] = useState(false)
  const [walkerIds, setWalkerIds] = useState<string[]>([])
  const [originalWalkerIds, setOriginalWalkerIds] = useState<string[]>([])
  const [activeUsers, setActiveUsers] = useState<UserType[]>([])
  const [walkerChanges, setWalkerChanges] = useState<WalkerChanges>({})
  const [camp, setCamp] = useState<Walk>()

  // Effect to slide in the panel
  useEffect(() => {
    setAddClass('wide')
  }, [])

  // Effect to set the active walkers
  useEffect(() => {
    const users =
      usersDexie?.filter((u) => u.status !== 'deleted' && !u.isBlocked) || []
    users.sort((a, b) => a.fullName.localeCompare(b.fullName))
    setActiveUsers(users)
  }, [usersDexie])

  // Effect to get the walk info
  useEffect(() => {
    if (!props.walkId) return
    const walkDexie = walksDexie?.find((w) => w.walkId === props.walkId)
    if (walkDexie) {
      setWalk(walkDexie)
      setWalkerIds(walkDexie.walkers)
      setOriginalWalkerIds(walkDexie.walkers)
      setWalkerChanges(walkDexie.walkerChangesByLeader || {})

      if (walkDexie.category === 'campWalk' && walkDexie.belongsToId) {
        const _camp = walksDexie?.find(
          (w) => w.walkId === walkDexie.belongsToId
        )
        setCamp(_camp)
      }
    }
  }, [walksDexie, props.walkId])

  const toggleWalker = (id: string) => {
    const currentChange = walkerChanges[id] || WalkerChangeType.NoChange
    if (walkerIds.includes(id)) {
      // Remove this walker
      setWalkerIds(walkerIds.filter((wId) => wId !== id))
      setWalkerChanges({
        ...walkerChanges,
        [id]: currentChange + WalkerChangeType.Removed,
      })
    } else {
      // Add this walker
      const newIds = [...walkerIds, id]
      setWalkerIds(newIds)
      setWalkerChanges({
        ...walkerChanges,
        [id]: currentChange + WalkerChangeType.Added,
      })
    }
  }

  const selectUser = (event: any) => {
    const userId = event.target.value

    if (userId === 'preRegisterAll') {
      // Pre-register all camp persons
      for (const id of camp?.walkers || []) {
        registerUser(id)
      }
    } else {
      registerUser(userId)
    }
  }

  const registerUser = (userId: string) => {
    const selectedUser = usersDexie?.find((u) => u.userId === userId)
    if (!selectedUser) return

    setWalkerIds((prev) => {
      return [...new Set([...prev, selectedUser.userId])]
    })
    setOriginalWalkerIds((prev) => {
      return [...new Set([...prev, selectedUser.userId])]
    })
    const currentChange = walkerChanges[userId] || WalkerChangeType.NoChange
    setWalkerChanges((prev) => {
      return {
        ...prev,
        [userId]: currentChange + WalkerChangeType.Added,
      }
    })
  }

  const updateTheList = async () => {
    if (!walk) return
    props.onClick()

    // Ensure we're upto date in case other walkers have processed their changes already
    await syncWithRemote()

    await updateWalk({
      ...walk,
      walkers: walkerIds,
      walkerChangesByLeader: walkerChanges,
    })
    syncWithRemote()
  }

  return (
    <>
      <Box className={`makeWalkContainer ${addClass}`} bg={heavyBgColor}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Text as={'b'}>Walkers List for "{walk?.title}"</Text>
        </Box>

        <SimpleGrid columns={6} alignItems="center">
          <GridItem colSpan={6} h={8}>
            <Text as={'b'} color={palette.titleSec}>
              {walkerIds.length} Walker
              {walkerIds.length && walkerIds.length > 1 ? 's' : ''}
            </Text>
          </GridItem>
          {originalWalkerIds.map((walkerId, index) => (
            <Fragment key={`${walkerId}-${index}`}>
              <GridItem as={'i'} fontSize={'sm'} colSpan={1}>
                {/* The checkbox */}
                <Checkbox
                  id={'walking-' + walkerId}
                  isChecked={walkerIds.includes(walkerId)}
                  onChange={() => toggleWalker(walkerId)}
                  disabled={isLeader(walkerId, walk, true)}
                />
              </GridItem>
              <GridItem colSpan={5}>
                {/* The walker */}
                {usersDexie?.find((u) => u.userId === walkerId)?.fullName}
              </GridItem>
            </Fragment>
          ))}
          <GridItem colSpan={6} h={8} display={'flex'} alignItems={'center'}>
            <Divider />
          </GridItem>
          <GridItem
            colSpan={6}
            h={8}
            mt={2}
            mb={2}
            display={'flex'}
            alignItems={'center'}
          >
            <Box>
              Add a walker from this list:
              <Select variant="filled" placeholder=" " onChange={selectUser}>
                {/* For camp walks, the pre-register option */}
                {camp && (
                  <option
                    key={'pre-register'}
                    value={'preRegisterAll'}
                  >{`All ${camp.walkers.length} people now registered at camp`}</option>
                )}
                {/* List of users */}
                {(activeUsers || []).map((user) => {
                  return (
                    <option
                      key={'user' + user.userId}
                      value={user.userId}
                      disabled={walkerIds.includes(user.userId)}
                    >{`${user.fullName}`}</option>
                  )
                })}
              </Select>
            </Box>
          </GridItem>
          <GridItem colSpan={6} h={8} display={'flex'} alignItems={'center'}>
            <Divider />
          </GridItem>
          <GridItem
            colSpan={6}
            h={8}
            mt={2}
            mb={2}
            display={'flex'}
            alignItems={'center'}
          >
            <Box>
              <Button
                ml={1}
                size={'sm'}
                leftIcon={<AddIcon />}
                variant="solid"
                onClick={() => setShowAddNewWalker(!showAddNewWalker)}
              >
                Add a new walker to the app
              </Button>
            </Box>
          </GridItem>
          <GridItem colSpan={6} h={8} display={'flex'} alignItems={'center'}>
            <Divider />
          </GridItem>
          <GridItem
            colSpan={6}
            h={8}
            display={'flex'}
            justifyContent={'flex-end'}
          >
            <IconButton
              icon={<CheckIcon />}
              size={'sm'}
              ml={4}
              colorScheme={'orange'}
              onClick={updateTheList}
              aria-label={'Done - walkers list updated'}
            />
          </GridItem>
        </SimpleGrid>

        {/* Add new walker panel */}
        {showAddNewWalker && (
          <AddNewWalker
            walkId={props.walkId}
            onClick={() => setShowAddNewWalker(false)}
          />
        )}
      </Box>
    </>
  )
}
