import { log } from '../common/utils'
import { AppStatus, db } from '../store/db'
import isOnline from 'is-online'
import { syncWithRemote } from '../common/syncWithRemote'

let isRegistered = false

// Online and other events
export const systemListeners = (appStatusDexie: AppStatus | undefined) => {
  if (isRegistered) return
  isRegistered = true

  // Determine the online internet status
  isOnline().then((onlineStatus) => {
    if (appStatusDexie?.haveInternet === onlineStatus) return
    db.appStatus.update('Tripsheets', { haveInternet: onlineStatus })
    // log(`On render: ${onlineStatus ? '"online"' : '"not-online"'}`)
  })

  // Define the colour scheme
  const initialColourTheme = window.matchMedia('(prefers-color-scheme: dark)')
  // log(`Colour theme: ${initialColourTheme.matches ? 'dark' : 'light'}`)
  db.appStatus.update('Tripsheets', { isDarkTheme: initialColourTheme.matches })
  window
    .matchMedia('(prefers-color-scheme: dark)')
    .addEventListener('change', (event) => {
      log(`Changed colour theme: ${event.matches ? 'dark' : 'light'}`)
      db.appStatus.update('Tripsheets', { isDarkTheme: event.matches })
    })

  // Subscribe to visibility change events
  document.addEventListener('onbeforeunload', function () {
    // This never fires i believe?
    log('Before app unload, will start sync')
    syncWithRemote()
  })

  document.addEventListener('visibilitychange', function () {
    // Fires when user switches tabs, apps, goes to homescreen, etc.
    // Is there any other state?
    if (
      document.visibilityState === 'hidden' ||
      document.visibilityState === 'visible'
    ) {
      log(`App is ${document.visibilityState}, will start sync`)
      syncWithRemote()
    }
  })
}
